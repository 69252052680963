angular.module('LeasePilot').factory('LabelsService', [
  'railsResourceFactory',
  'railsSerializer',
  '$q',
  '$rootScope',
  function($q, $rootScope) {
    var defaultLabels = {
      'lease.abatementDates.date': {
        short: 'Month {#}',
        full: 'Month {#}',
      },
      'lease.additionalRentType': {
        short: 'Additional Rent Type',
      },
      'lease.advanceRentAmount': {
        short: 'Amount',
        full: 'Advance Rent Amount',
      },
      'lease.allowanceScheduleType': {
        short: 'Allowance Schedule Type',
        full: 'Allowance Schedule Type',
      },
      'lease.annualIncrease': {
        short: 'Annual Increase',
      },
      'lease.minimumIncreaseCPI': {
        short: 'CPI Increase Minimum',
      },
      'lease.hasLeaseAssignment': {
        short: 'Tenant Assigning Lease',
      },
      'lease.hasLenderConsentRequired': {
        short: 'Lender Consent Required',
      },
      'lease.hasRequiresDemisingWork': {
        short: 'Requires Demising Work',
      },
      'lease.hasRulesAndRegsExhibit': {
        short: "Attach Rules & Regulations",
      },
      'lease.hasOutparcel': {
        short: 'Outparcel',
      },
      'lease.hasGreenLease': {
        short: 'Green Lease'
      },
      'lease.hasCenterImprovements': {
        short: 'Center Improvements'
      },
      'lease.hasTenantCAMContribution': {
        short: 'Tenant CAM Contribution'
      },
      'lease.hasTenantMaintainsHVAC': {
        short: 'Tenant Maintains HVAC'
      },
      'lease.hasSustainabilityPractices': {
        short: 'Sustainability Practices'
      },
      'lease.hasTenantOfacRep': {
        short: 'Tenant OFAC Representation'
      },
      'lease.hasPaymentSystem': {
        short: 'Payment System'
      },
      'lease.lenderName': {
        short: 'Lender',
      },
      'lease.assigneeInfo.name': {
        short: 'New Tenant Name',
      },
      'lease.assigneeInfo.type': {
        short: 'New Tenant Info',
      },
      'lease.assigneeInfo.stateOfFormation': {
        short: 'State Formation',
      },
      'lease.assigneeInfo.entityType': {
        short: 'Entity Type',
      },
      'lease.assigneeInfo.entityTypeOther': {
        short: 'Entity Type (Other)',
      },
      'lease.assigneeInfo.address': {
        short: 'Address',
      },
      'lease.assigneeInfo.addressCity': {
        short: 'City',
      },
      'lease.assigneeInfo.addressState': {
        short: 'State',
      },
      'lease.assigneeInfo.addressZip': {
        short: 'Zip',
      },
      'lease.assigneeInfo.attentionName': {
        short: 'Attention',
      },
      'lease.assigneeInfo.assignmentDate': {
        short: 'Assignment Date',
      },
      'lease.assignment': {
        short: 'Landlord Approval Rights',
        full: 'Assignment & Sublet',
      },
      'lease.sublet': {
        short: 'Landlord Approval Rights For Sublet',
      },
      'lease.auditRightType': {
        short: 'Type',
        full: 'Audit Right Type',
      },
      'lease.auditRightReimbursementsTrigger': {
        short: 'Increase that Triggers Right',
      },
      'lease.baseRent': {
        short: 'Base Rent',
      },
      'lease.baseRent.unit': {
        short: '$/Sf',
      },
      'lease.monthlyBaseAmountForExpenses': {
        short: 'Base Amount for Expenses',
      },
      'lease.monthlyBaseAmountForTaxes': {
        short: 'Base Amount for Taxes',
      },
      'lease.monthlyBaseAmountForInsurance': {
        short: 'Base Amount for Insurance',
      },
      'lease.burnOff': {
        short: 'Burn Off Void in case of',
        full: 'Burn Off',
      },
      'lease.burnOffPeriod': {
        short: 'Period Before Burn Off Effective',
        full: 'Burn Off Period',
      },
      'lease.camCap.firstYearAmount': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.camCap.annualIncrease': {
        short: 'Annual Increase',
        full: 'Annual Increase',
      },
      'lease.capOnCam': {
        short: 'Amount',
        full: 'Cap on CAM',
      },
      'lease.capOnCamFirstYear': {
        short: 'First Year to Which Cap Applies',
      },
      'lease.capOnCamFirstYear.unit': {
        short: 'Calendar Year',
      },
      'lease.camCap.capOnCamType': {
        short: 'Cap on CAM Type',
      },
      'lease.capOnTenantImprovements': {
        short: 'Cap on Tenant Improvements'
      },
      'lease.commonAreaReimbursement': {
        short: 'Estimated CAM',
        full: 'Estimated CAM',
      },
      'lease.commonAreaReimbursement.unit': {
        short: '$/Sf',
      },
      'lease.commonAreaWorkPayment': {
        short: 'Who Pays for Common Area Work',
        full: 'Common Area Work Payment',
      },
      'lease.commonAreaWorkPlan.url': {
        short: 'Upload Common Area Work - Schedule C-1',
      },
      'lease.contingencyTermination': {
        short: 'Current Lease Ends by:',
        full: 'Contingency Termination Option',
      },
      'lease.cpiAnchorYears': {
        short: 'Applies After Lease Year',
        full: 'CPI Anchor Year',
      },
      'lease.coverPage.url': {
        short: 'Upload Cover Page',
      },
      'lease.deliveryDateType': {
        short: 'Delivery Date',
        full: 'Delivery Date',
      },
      'lease.deliveryDaysAfterLease': {
        short: 'Days After Lease Execution',
        full: 'Days After Lease Execution',
      },
      'lease.designSchedule.url': {
        short: 'Upload Design and Construction Schedule',
      },
      'lease.damageDeposit': {
        short: 'Amount',
      },
      'lease.cleaningFee': {
        short: 'Amount',
      },
      'lease.hasLandlordToUseDeductibleToRestore': {
        short: 'Landlord to Use Deductible to Restore'
      },
      'lease.hasLandlordDemoTermination': {
        short: 'Landlord Termination for Demo',
      },
      'lease.demoHardTerminationDate': {
        short: 'Hard Termination Date',
      },
      'lease.demoSoftTerminationDate': {
        short: 'Soft Termination Date',
      },
      'lease.designationOfImprovementsAsBuildingStandard': {
        short: 'Designation of Improvements as Building Standard',
      },
      'lease.earlyAccessPeriod': {
        short: 'Early Access Period',
      },
      'lease.earlyAccessDateType': {
        short: 'Access Date',
      },
      'lease.earlyAccessType': {
        short: 'Access Type',
      },
      'lease.landlordArchitect': {
        short: 'Landlord Architect',
      },
      'lease.landlordRepresentative': {
        short: 'Landlord Representative',
      },
      'lease.landlordRelocationRightType': {
        short: 'Relocation Type',
      },
      'lease.landlordEstimatedCommencementDate': {
        short: 'Estimated Commencement Date',
      },
      'lease.hasElectricalLoad': {
        short: 'Electrical Load',
        full: 'Building Electrical Load',
      },
      'lease.electricalLoad': {
        short: 'Watts',
        full: 'Electrical Load (Watts)',
      },
      'lease.estimatedCommencementDate': {
        short: 'Date',
      },
      'lease.estimatedCommencementDateType': {
        short: 'Estimated Term Commencement Date',
      },
      'lease.estimatedDeliveryDate': {
        short: 'Estimated Delivery Date',
      },
      'lease.estimatedDeliveryDateTia': {
        short: 'Estimated Delivery Date',
      },
      'lease.exclusiveParkingAmount': {
        short: 'No. of Spaces',
        full: 'Exclusive Parking Amount',
      },
      'lease.extendingTermBumpsKeyedOf': {
        short: 'Extending Term Bumps Keyed Off',
      },
      'lease.exclusiveParkingDesignation': {
        short: 'Parking Designation',
        full: 'Exclusive Parking Designation',
      },
      'lease.exclusives.url': {
        short: 'Upload Exhibit B',
        full: 'Exclusives',
      },
      'lease.exclusiveUse.carveOutLanguage': {
        short: 'Exclusive Use Carve Out Language',
      },
      'lease.exclusiveUse.hasCarveOut': {
        short: 'Exclusive Use Carve Out',
      },
      'lease.exclusiveUse.hasRemedy': {
        short: 'Rent Abatement for Landlord Violation',
        full: 'Exclusive Use Remedy',
      },
      'lease.exclusiveUse.language': {
        short: 'Text for Exclusive Use',
        full: 'Exclusive Use Language',
      },
      'lease.exclusiveUse.primaryPercentage': {
        short: 'Primary Use',
      },
      'lease.exclusiveUse.scope': {
        short: 'Area Subject to Exclusive Use',
        full: 'Exclusive Use Scope',
      },
      'lease.exclusiveUses.url': {
        short: 'Upload Exhibit A-2',
        full: 'Exclusive Use on Exhibit',
      },
      'lease.existingGuarantorInfo.entities.guarantyDate': {
        short: 'Date',
        full: 'Entities Guaranty Date',
      },
      'lease.existingGuarantorInfo.entities.hasBeenReleased': {
        short: 'Release Guarantor',
      },
      'lease.existingGuarantorInfo.entities.name': {
        short: 'Name',
      },
      'lease.existingGuarantorInfo.entities.nickname': {
        short: 'Nick Name',
      },
      'lease.existingGuarantorInfo.entities.releaseDate': {
        short: 'Release Date',
        full: 'Entities Release Date',
      },
      'lease.existingGuarantorInfo.entities.address': {
        short: 'Address',
      },
      'lease.existingGuarantorInfo.entities.city': {
        short: 'City',
      },
      'lease.existingGuarantorInfo.entities.entityType': {
        short: 'Entity Type',
      },
      'lease.existingGuarantorInfo.entities.entityTypeOther': {
        short: 'Entity Type (Other)',
      },
      'lease.existingGuarantorInfo.entities.state': {
        short: 'State',
      },
      'lease.existingGuarantorInfo.entities.stateOfFormation': {
        short: 'State of Formation',
      },
      'lease.existingGuarantorInfo.entities.zip': {
        short: 'Zip',
      },
      'lease.existingGuarantorInfo.individuals.guarantyDate': {
        short: 'Date',
        full: 'Individuals GuarantyDate',
      },
      'lease.existingGuarantorInfo.individuals.hasBeenReleased': {
        short: 'Release Guarantor',
      },
      'lease.existingGuarantorInfo.individuals.name': {
        short: 'Name',
      },
      'lease.existingGuarantorInfo.individuals.nickname': {
        short: 'Nick Name',
      },
      'lease.existingGuarantorInfo.individuals.releaseDate': {
        short: 'Release Date',
        full: 'Individuals ReleaseDate',
      },
      'lease.existingGuarantorInfo.individuals.address': {
        short: 'Address',
      },
      'lease.existingGuarantorInfo.individuals.city': {
        short: 'City',
      },
      'lease.existingGuarantorInfo.individuals.state': {
        short: 'State',
      },
      'lease.existingGuarantorInfo.individuals.zip': {
        short: 'Zip',
      },
      'lease.existingGuarantorInfo.marriedCouples.guarantyDate': {
        short: 'Date',
        full: 'MarriedCouples GuarantyDate',
      },
      'lease.existingGuarantorInfo.marriedCouples.hasBeenReleased': {
        short: 'Release Guarantor',
      },
      'lease.existingGuarantorInfo.marriedCouples.name1': {
        short: 'Name 1',
      },
      'lease.existingGuarantorInfo.marriedCouples.name2': {
        short: 'Name 2',
      },
      'lease.existingGuarantorInfo.marriedCouples.nickname': {
        short: 'Nick Name',
      },
      'lease.existingGuarantorInfo.marriedCouples.releaseDate': {
        short: 'Release Date',
        full: 'MarriedCouples ReleaseDate',
      },
      'lease.existingLeaseDate': {
        short: 'Lease Commencement Date',
        full: 'Existing LeaseDate',
      },
      'lease.existingLeaseExpirationDate': {
        short: 'Lease Expiration Date',
        full: 'Existing Lease Expiration Date',
      },
      'lease.existingPercentageRentRate': {
        short: 'Precentage Rent Rate',
        full: 'Existing Percentage Rent Rate',
      },
      'lease.existingTenantContingency': {
        short: 'Premises Currently Occupied',
        full: 'Existing Tenant Contingency',
      },
      'lease.existingTenantName': {
        short: 'Existing Tenant Name',
      },
      'lease.expansionAgreementDate': {
        short: 'Date for Agreement on Terms',
      },
      'lease.expansionAreaPlan.url': {
        short: 'Upload expansion Area plans',
        full: 'Expansion Area Plan',
      },
      'lease.generatorExhibit.url': {
        short: 'Upload Generator Exhibit',
        full: 'Generator Exhibit',
      },
      'lease.expansionCommencementDate': {
        short: 'Expansion Premises Commencement Date',
        full: 'Expansion Commencement Date',
      },
      'lease.expansionNoticeDate': {
        short: 'Date Notice Required',
      },
      'lease.expansionNoticeSoftDate': {
        short: 'Date Notice Required',
      },
      'lease.expansionOption.area': {
        short: 'Expansion Area Size',
        full: 'Area',
      },
      'lease.expansionOption.date': {
        short: 'Date',
        full: 'Expansion Option Date',
      },
      'lease.expansionOption.deliveryDate': {
        short: 'Expansion Delivery Date Options',
        full: 'Delivery Date',
      },
      'lease.expansionOption.days': {
        short: 'Delivery Date',
      },
      'lease.expansionOption.hasImprovementAllowance': {
        short: 'Expansion Area Improvement Allowance',
        full: 'Improvement Allowance',
      },
      'lease.expansionOption.hasSuperiorNamedTenants': {
        short: 'Named Tenants Have Superior Rights',
        full: 'Superior Named Tenants',
      },
      'lease.expansionOption.improvementAllowanceAmount': {
        short: 'Amount',
        full: 'Improvement Allowance Amount',
      },
      'lease.expansionOption.suite': {
        short: 'Suite',
      },
      'lease.expansionOption.floor': {
        short: 'Floor',
      },
      'lease.expansionOption.superiorNamedTenants': {
        short: 'Specified Tenant(s)',
        full: 'Superior Named Tenants',
      },
      'lease.expansionOption.windowPeriod': {
        short: 'Period',
        full: 'Window Period',
      },
      'lease.expansionOption.windowStartDate': {
        short: 'Window Start Date',
      },
      'lease.expansionPremiseAreaSize': {
        short: 'Expansion Area Size',
        full: 'Expansion Premise Area Size',
      },
      'lease.expansionPremiseStoreroomNumber': {
        short: 'Expansion Storeroom No.',
        full: 'Expansion Premise Storeroom Number',
      },
      'lease.expansionPremises.url': {
        short: 'Upload Expansion Area',
      },
      'lease.expenseBaseYear': {
        short: 'Expense Base Year',
      },
      'lease.fixedCamAnnualEscalator': {
        short: 'Annual Escalation',
        full: 'Fixed Cam Annual Escalator',
      },
      'lease.camType': {
        short: 'CAM Reimbursement',
      },
      'lease.camType.passthrough': {
        short: 'Passthrough',
      },
      'lease.camType.fixed': {
        short: 'Fixed',
      },
      'lease.fixedCam': {
        short: 'Fixed CAM',
        full: 'Fixed CAM',
      },
      'lease.fixedCamInitialAmount': {
        short: 'Initial Amount',
        full: 'Fixed CAM - Initial Amount',
      },
      'lease.fixedCamType': {
        short: 'Cam Period',
        full: 'Fixed CAM Type',
      },
      'lease.rentPeriods.totalRent': {
        short: 'Rent Amount',
      },
    
      'lease.fixturing.backendRentCommencementDateDaysAfterDelivery': {
        short: 'Days After Delivery',
      },
      'lease.fixturing.backendRentCommencementDateHardDate': {
        short: 'Date',
      },
      'lease.fixturing.hasBackendRentCommencementDate': {
        short: 'Backend Rent Commencement Date',
      },
      'lease.fixturing.hasLinkedToPermits': {
        short: 'Linked To Permits',
      },
      'lease.fixturingPeriodType': {
        short: 'Buildout Period Type',
      },
      'lease.fixturingPeriod': {
        short: 'Period',
        full: 'Fixturing Period',
      },
      'lease.fixturingPeriod.unit': {
        short: 'Days After Delivery',
      },
      'lease.franchise': {
        short: 'Franchise',
      },
      'lease.franchisorAddendum': {
        short: 'Franchisor Addendum',
      },
      'lease.franchisorInfo.address': {
        short: 'Address',
        full: 'Franchisor Address',
      },
      'lease.franchisorInfo.attention': {
        short: 'Attention:',
        full: 'Franchisor Attention',
      },
      'lease.franchisorInfo.city': {
        short: 'City',
        full: 'Franchisor City',
      },
      'lease.franchisorInfo.entityType': {
        short: 'Entity Type',
        full: 'Franchisor Entity Type',
      },
      'lease.franchisorInfo.entityTypeOther': {
        short: 'Entity Type (Other)',
        full: 'Franchisor Other Entity Type',
      },
      'lease.franchisorInfo.name': {
        short: 'Franchisor Name',
      },
      'lease.franchisorInfo.stateOfFormation': {
        short: 'State of Formation',
        full: 'Franchisor State',
      },
      'lease.franchisorInfo.state': {
        short: 'State',
        full: 'Franchisor State',
      },
      'lease.franchisorInfo.zip': {
        short: 'Zip',
        full: 'Franchisor Zip',
      },
      'lease.freeRentAbatedBaseRent': {
        short: 'Abated Base Rent',
      },
      'lease.freeRentBeginInMonth': {
        short: 'Begins in',
      },
      'lease.freeRentExtendedAt': {
        short: 'Extended Lease Year at:',
      },
      'lease.freeRent': {
        short: 'Period',
        full: 'Free Rent Period',
      },
      'lease.freeRentPeriods': {
        short: 'Free Rent Periods',
      },
      'lease.freeRentPeriods.isFree': {
        short: 'Year {#}',
      },
      'lease.freeRentPeriods.hardStartDate': {
        short: 'Start',
      },
      'lease.freeRentPeriods.hardEndDate': {
        short: 'End',
      },
      'lease.freeRentPeriods.softStartDate': {
        short: 'Start',
      },
      'lease.freeRentPeriods.softEndDate': {
        short: 'End',
      },
      'lease.freeRentPeriods.beginIn': {
        short: 'Begin in (Month)',
      },
      'lease.freeRentPeriods.duration': {
        short: 'Duration (Months)',
      },
      'lease.freeRentPeriods.percentFree': {
        short: '% Free',
      },
      'lease.freeRentPeriods.amount': {
        short: 'Abated Base Rent',
      },
      'lease.freeRentType': {
        short: 'Free Rent Applies To:',
        full: 'Free Rent Type',
      },
      'lease.goDarkOperatingPeriod': {
        short: 'Go Dark',
        full: 'Go Dark Operating Period',
      },
      'lease.goDarkOperatingPeriod.unit': {
        short: 'Year(s)',
      },
      'lease.goDarkGrossSalesFloorAmount': {
        short: 'Gross Sales Floor Amount',
      },
      'lease.goDarkOption': {
        short: 'Go Dark Option',
        full: 'Period of Time Tenant is Obligated to be Open',
      },
      'lease.grandOpeningPeriod': {
        short: 'Period',
        full: 'Grand Opening Period',
      },
      'lease.guarantor': {
        short: 'Guarantor',
      },
      'lease.hasGuarantyCapBurndown': {
        short: 'Add Burndown',
      },
      'lease.guarantyCapBurndownType': {
        short: 'Burndown Amount Type',
        full: 'Burndown Amount Type',
      },
      'lease.guarantyCapBurnOffReductionPeriodsNumber': {
        short: 'No. of Reduction Periods',
      },
      'lease.guarantyCapBurnOffReductionPeriods.reducedAmount': {
        short: 'Amount Reduced To:',
      },
      'lease.guarantyCapBurnOffReductionPeriods.year': {
        short: 'Reduction Date No. {#}',
      },
      'lease.addGuarantor': {
        short: 'Add Guarantor',
        full: 'Add Guarantor',
      },
      'lease.guarantor.addGuarantor': {
        full: 'Add Guarantor',
      },
      'lease.guarantor.removeGuarantor': {
        full: 'Remove Guarantor',
      },
      'lease.guarantorInfo.entities.address': {
        short: 'Address',
        full: 'Guarantor Address',
      },
      'lease.guarantorInfo.entities.attention': {
        short: 'Attention To:',
      },
      'lease.guarantorInfo.entities.city': {
        short: 'City',
        full: 'Guarantor Address City',
      },
      'lease.guarantorInfo.entities.entityType': {
        short: 'Entity Type',
        full: 'Guarantor Entity Type',
      },
      'lease.guarantorInfo.entities.entityTypeOther': {
        short: 'Entity Type (Other)',
        full: 'Guarantor Entity Type Other',
      },
      'lease.guarantorInfo.entities.name': {
        short: 'Guarantor Name',
      },
      'lease.guarantorInfo.entities.phone': {
        short: 'Phone',
      },
      'lease.guarantorInfo.entities.email': {
        short: 'Email',
      },
      'lease.guarantorInfo.entities.stateOfFormation': {
        short: 'State of Formation',
        full: 'Guarantor State Of Formation',
      },
      'lease.guarantorInfo.entities.state': {
        short: 'State',
        full: 'Guarantor Address State',
      },
      'lease.guarantorInfo.entities.taxId': {
        short: 'Tax ID',
      },
      'lease.guarantorInfo.entities.zip': {
        short: 'Zip',
        full: 'Guarantor Address Zip',
      },
      'lease.guarantorInfo.entities.agentName': {
        short: 'Agent Name',
      },
      'lease.guarantorInfo.entities.agentAddress': {
        short: 'Address',
      },
      'lease.guarantorInfo.entities.agentCity': {
        short: 'City',
      },
      'lease.guarantorInfo.entities.agentState': {
        short: 'State',
      },
      'lease.guarantorInfo.entities.agentZip': {
        short: 'Zip',
      },
      'lease.guarantorInfo.individuals.address': {
        short: 'Address',
      },
      'lease.guarantorInfo.individuals.city': {
        short: 'City',
      },
      'lease.guarantorInfo.individuals.name': {
        short: 'Guarantor Name',
      },
      'lease.guarantorInfo.individuals.phone': {
        short: 'Phone',
      },
      'lease.guarantorInfo.individuals.email': {
        short: 'Email',
      },
      'lease.guarantorInfo.individuals.SSN': {
        short: 'Social Security Number',
      },
      'lease.guarantorInfo.individuals.state': {
        short: 'State',
      },
      'lease.guarantorInfo.individuals.zip': {
        short: 'Zip',
      },
      'lease.guarantorInfo.marriedCouples.address1': {
        short: 'Address',
        full: 'Married Guarantor Address 1',
      },
      'lease.guarantorInfo.marriedCouples.address2': {
        short: 'Address',
        full: 'Married Guarantor Address 2',
      },
      'lease.guarantorInfo.marriedCouples.city1': {
        short: 'City',
        full: 'Married Guarantor City 1',
      },
      'lease.guarantorInfo.marriedCouples.city2': {
        short: 'City',
        full: 'Married Guarantor City 2',
      },
      'lease.guarantorInfo.marriedCouples.hasSameAddress': {
        short: 'Address Same As Above',
        full: 'Married Guarantor has Same Address',
      },
      'lease.guarantorInfo.marriedCouples.name1': {
        short: 'Guarantor Name',
        full: 'Married Guarantor Name 1',
      },
      'lease.guarantorInfo.marriedCouples.name2': {
        short: 'Guarantor Name',
        full: 'Married Guarantor Name 2',
      },
      'lease.guarantorInfo.marriedCouples.phone1': {
        short: 'Phone',
      },
      'lease.guarantorInfo.marriedCouples.phone2': {
        short: 'Phone',
      },
      'lease.guarantorInfo.marriedCouples.email1': {
        short: 'Email',
      },
      'lease.guarantorInfo.marriedCouples.email2': {
        short: 'Email',
      },
      'lease.guarantorInfo.marriedCouples.state1': {
        short: 'State',
        full: 'Married Guarantor State 1',
      },
      'lease.guarantorInfo.marriedCouples.state2': {
        short: 'State',
        full: 'Married Guarantor State 2',
      },
      'lease.guarantorInfo.marriedCouples.zip1': {
        short: 'Zip',
        full: 'Married Guarantor Zip 1',
      },
      'lease.guarantorInfo.marriedCouples.zip2': {
        short: 'Zip',
        full: 'Married Guarantor Zip 2',
      },
      'lease.existingGuarantorInfo.marriedCouples.address1': {
        short: 'Address',
        full: 'Married Existing Guarantor Address 1',
      },
      'lease.existingGuarantorInfo.marriedCouples.address2': {
        short: 'Address',
        full: 'Married Existing Guarantor Address 2',
      },
      'lease.existingGuarantorInfo.marriedCouples.city1': {
        short: 'City',
        full: 'Married Existing Guarantor City 1',
      },
      'lease.existingGuarantorInfo.marriedCouples.city2': {
        short: 'City',
        full: 'Married Existing Guarantor City 2',
      },
      'lease.existingGuarantorInfo.marriedCouples.hasSameAddress': {
        short: 'Address Same As Above',
        full: 'Married Existing Guarantor has Same Address',
      },
      'lease.existingGuarantorInfo.marriedCouples.state1': {
        short: 'State',
        full: 'Married Existing Guarantor State 1',
      },
      'lease.existingGuarantorInfo.marriedCouples.state2': {
        short: 'State',
        full: 'Married Existing Guarantor State 2',
      },
      'lease.existingGuarantorInfo.marriedCouples.zip1': {
        short: 'Zip',
        full: 'Married Existing Guarantor Zip 1',
      },
      'lease.existingGuarantorInfo.marriedCouples.zip2': {
        short: 'Zip',
        full: 'Married Existing Guarantor Zip 2',
      },
      'lease.guarantorInfo.marriedCouples.SSN1': {
        short: 'Social Security Number',
      },
      'lease.guarantorInfo.marriedCouples.SSN2': {
        short: 'Social Security Number',
      },
      'lease.hasDeliveryDeadline': {
        short: 'Delivery Deadline',
      },
      'lease.hardEstimatedDeliveryDate': {
        short: 'Estimated Delivery Date',
        full: 'Hard Estimated Delivery Date',
      },
      'lease.hardTerminationDate': {
        short: 'Termination Date',
        full: 'Hard Termination Date',
      },
      'lease.hasAccelerationFee': {
        short: 'Acceleration Fee',
      },
      'lease.accelerationFeeAmount': {
        short: 'Amount',
      },
      'lease.hasAdaCompliance': {
        short: 'ADA Compliance',
      },
      'lease.hasAdvanceRent': {
        short: 'Advance Rent',
      },
      'lease.hasApplyIncreaseToRenewals': {
        short: 'Apply Increase to Renewal(s)',
        full: 'Apply Increase to Renewal(s)',
      },
      'lease.hasAreaRemeasurement': {
        short: 'Landlord Remeasurement Right',
        full: 'Area Remeasurement',
      },
      'lease.hasAuditRight': {
        short: 'Tenant Audit Right',
        full: 'Audit Right',
      },
      'lease.hasAugmentedHazSub': {
        short: 'Augmented Hazardous Substances',
        full: 'Augmented Hazardous Substances',
      },
      'lease.hasBaseYearReviewRights': {
        short: 'Base Year Review Rights',
      },
      'lease.hasBurnOff': {
        short: 'Burn Off',
        full: 'Burn Off',
      },
      'lease.hasCapOnCam': {
        short: 'Cap on CAM',
      },
      'lease.hasCleaningSpecs': {
        short: 'Add Cleaning Specs',
      },
      'lease.hasCommonAreaWork': {
        short: 'Landlord is Doing Common Area Work',
        full: 'Common Area Work',
      },
      'lease.hasConfidentiality': {
        short: 'Confidentiality',
      },
      'lease.hasCompensationForLossOfGoodWill': {
        short: 'Compensation for Loss of Good Will',
      },
      'lease.hasConvertUnreservedToReservedParking': {
        short: 'Convert Unreserved to Reserved Parking',
      },
      'lease.hasCopyNoticeAddress': {
        short: 'With a Copy To:',
      },
      'lease.hasCpiRent': {
        short: 'Annual Rent Increase at CPI',
        full: 'CPI',
      },
      'lease.hasCustomAdvancedEdit': {
        short: 'Add Custom Advanced Edit',
      },
      'lease.hasCustomExhibit': {
        short: 'Add Custom Exhibit',
      },
      'lease.hasDamageDeposit': {
        short: 'Damage Deposit',
      },
      'lease.hasCleaningFee': {
        short: 'Cleaning Fee',
      },
      'lease.hasEarlyAccess': {
        short: 'Early Access',
      },
      'lease.hasEntireFloor': {
        short: 'Entire Floor',
      },
      'lease.hasExclusiveParking': {
        short: 'Exclusive Parking',
      },
      'lease.hasExclusiveUse': {
        short: 'Exclusive Use',
      },
      'lease.hasExclusiveUse.scope': {
        short: 'Area Subject to Exclusive Use',
      },
      'lease.hasExclusiveUseExhibit': {
        short: 'Exclusive Use on Exhibit',
      },
      'lease.hasExerciseRenewalOption': {
        short: 'Exercise Renewal Option',
      },
      'lease.hasAdditionalOptionsPursuantToLease': {
        short: 'Additional Options Pursuant To Lease',
      },
      'lease.hasExistingGuarantors': {
        short: 'Existing Guarantor(s)',
        full: 'Existing Guarantors',
      },
      'lease.existingGuarantorsGuarantorInfo': {
        short: 'Guarantor Info',
      },
      'lease.hasExistingOptionRemoved': {
        short: 'Remove Existing Options',
        full: 'Existing Option Removed',
      },
      'lease.hasExpansionOption': {
        short: 'Expansion Option',
      },
      'lease.hasExtensionOfTerm': {
        short: 'Extension of Term',
      },
      'lease.hasBlendAndExtend': {
        short: 'Blend And Extend'
      },
      'lease.hasExteriorSignage': {
        short: 'Exterior Signage Right',
      },
      'lease.hasFitTestAllowance': {
        short: 'Test Fit Allowance',
        full: 'Fit Test Allowance',
      },
      'lease.hvacCapAmount': {
        short: 'Amount',
      },
      'lease.fitTestAllowanceAmount': {
        short: 'Amount',
      },
      'lease.fitTestAllowanceAmount.unit': {
        short: '$/Sf',
      },
      'lease.hasFixturingPeriod': {
        short: 'Fixturing Period',
      },
      'lease.hasFreeRentAddedToTerm': {
        short: 'Add Free Rent to Term',
        full: 'Free Rent Added to Term',
      },
      'lease.hasFreeRent': {
        short: 'Free Rent',
      },
      'lease.hasFutureSNDA': {
        short: 'SNDA for Future Lender',
      },
      'lease.hasGoDark': {
        short: 'Go Dark',
      },
      'lease.hasGrandOpening': {
        short: 'Grand Opening',
      },
      'lease.hasHvacCap': {
        short: 'HVAC Cap',
      },
      'lease.hasHvacLandlordReplacement': {
        short: 'Landlord to Replace',
        full: 'HVAC Landlord Replace',
      },
      'lease.hasHvacSpecs': {
        short: 'Add HVAC Specs',
      },
      'lease.hasHvacTenantCap': {
        short: 'Tenant Cap',
        full: 'HVAC Tenant Cap',
      },
      'lease.hasHvacTenantMaintain': {
        short: 'Tenant to Maintain',
      },
      'lease.hasInitialDeliveryWork': {
        short: 'Landlord Does Initial Delivery Work in Premises',
        full: 'Initial Delivery Work',
      },
      'lease.hasImprovementsOnPlanDeemedBuildingStandard': {
        short: 'Improvements on Plan Deemed Building Standard',
      },
      'lease.hasKickout': {
        short: 'Kickout',
      },
      'lease.hasKickoutTerminationFee': {
        short: 'Termination Fee',
        full: 'Kickout Termination',
      },
      'lease.hasLandlordBroker': {
        short: 'Landlord\'s Broker',
      },
      'lease.hasLandlordConstructionCap': {
        short: 'Landlord\'s Construction Cost Cap',
      },
      'lease.hasLandlordCoordinationFee': {
        short: 'Landlord Coordination Fee',
      },
      'lease.hasLandlordDefault': {
        short: 'Landlord Default',
      },
      'lease.hasLandlordDoesTurnkey': {
        short: 'Turnkey',
      },
      'lease.hasLandlordExculpationCarveout': {
        short: 'Landlord Exculpation Carveout',
      },
      'lease.hasLandlordHazMatRep': {
        short: 'Landlord Haz Mat Rep',
      },
      'lease.hasLandlordIndemnity': {
        short: 'Landlord to indemnify Tenant',
        full: 'Landlord Indemnity',
      },
      'lease.hasLandlordInsuranceRequirement': {
        short: 'Landlord Required to Maintain Insurance',
        full: 'Landlord Insurance Requirement',
      },
      'lease.hasLandlordMaintenanceObligations': {
        short: 'Landlord Maintenance Obligations',
      },
      'lease.hasLandlordOfacRep': {
        short: 'Landlord OFAC Representation',
      },
      'lease.hasLandlordPermittedUseRep': {
        short: 'Landlord Rep re: Permitted Use',
      },
      'lease.hasLandlordWarranty': {
        short: 'Landlord Warranty',
        full: 'HVAC Warranty',
      },
      'lease.hasLandlordWorkPunchList': {
        short: 'Landlord Work Punch List',
      },
      'lease.hasLayoutPlan': {
        short: 'Attach Lay-Out Plan',
        full: 'Layout Plan',
      },
      'lease.hasLetterOfCreditDeliveredAfterLease': {
        short: 'L/C Delivered After Lease Execution',
        full: 'Letter of Credit Delivered After Lease',
      },
      'lease.hasLetterOfCredit': {
        short: 'Letter of Credit',
      },
      'lease.hasLimitedGrossSalesReporting': {
        short: 'Limited Gross Sales Reporting',
      },
      'lease.hasLimitedRelocation': {
        short: 'Limited Relocation Compromise',
      },
      'lease.hasMarketingCap': {
        short: 'Cap on Marketing Fee',
        full: 'Cap on Marketing Fee',
      },
      'lease.hasMitigationOfDamages': {
        short: 'Mitigation of Damages',
      },
      'lease.hasMonetaryCapOnGuaranty': {
        short: 'Monetary Cap',
        full: 'Guaranty Monetary Cap',
      },
      'lease.hasMonetaryDefaultNotice': {
        short: 'Notice for Monetary Default',
        full: 'Monetary Default Notice',
      },
      'lease.hasLandlordLienSubordinationExhibit': {
        short: 'Upload Lien Subordination Exhibit',
      },
      'lease.monetaryCapType': {
        short: 'Cap Type',
        full: 'Monetary Cap Type',
      },
      'lease.hasMonumentSign': {
        short: 'Monument Sign',
      },
      'lease.monetaryCapMonths': {
        short: 'Number of Months of Rent',
        full: 'Monetary Cap Number of Months',
      },
      'lease.hasMonthToMonth': {
        short: 'Existing Term Month-to-Month',
        full: 'Month to Month',
      },
      'lease.hasMutualIndemnity': {
        short: 'Make Indemnity Mutual',
        full: 'Mutual Indemnity',
      },
      'lease.hasOngoingCoTenancy': {
        short: 'Ongoing Co-Tenancy',
      },
      'lease.hasOpeningCoTenancy': {
        short: 'Opening Co-Tenancy',
      },
      'lease.hasOutdoorSeating': {
        short: 'Outdoor Seating',
      },
      'lease.hasPatioTenant': {
        short: 'Patio Tenant',
      },
      'lease.hasParking': {
        short: 'Parking',
      },
      'lease.hasPatriotActUpdate': {
        short: 'Add Patriot Act Compliance',
        full: 'Patriot Act',
      },
      'lease.hasPercentageRent': {
        short: 'Tenant Pays Percentage Rent',
        full: 'Percentage Rent',
      },
      'lease.hasPercentageRentUnnaturalEscalation': {
        short: 'Escalation',
        full: 'Unnatural Escalation',
      },
      'lease.hasPermitContingency': {
        short: 'Permit Contingency',
      },
      'lease.hasLiquorLicenseContingency': {
        short: 'Liquor License Contingency',
      },
      'lease.hasPermittedClosures': {
        short: 'Permitted Closures',
      },
      'lease.hasPermittedTransfer': {
        short: 'Permitted Transfers',
      },
      'lease.hasPermittedFranchisee': {
        short: 'Permitted Franchisee',
      },
      'lease.hasPermittedUseHazardousMaterials': {
        short: 'Hazardous Materials',
      },
      'lease.hasPermittedUseVendingMachinesForCustomer': {
        short: 'Vending Machine - Customer Use',
      },
      'lease.hasPermittedUseVendingMachinesForNonCustomer': {
        short: 'Vending Machine - Non Customer Use',
      },
      'lease.hasPermittedUseCommonGreaseTrap': {
        short: 'Common Grease Trap',
      },
      'lease.permittedUseCommonGreaseTrap': {
        short: 'Tenant\'s Grease Trap',
      },
      'lease.permittedUseCommonGreaseTrap.landlord': {
        short: 'Landlord Provided',
      },
      'lease.permittedUseCommonGreaseTrap.tenant': {
        short: 'Tenant Install',
      },
      'lease.hasPlansDeemedApproved': {
        short: 'Deemed Approval of Tenant Plans',
        full: 'Deemed Approval of Plans',
      },
      'lease.hasPostAddressSameAsPre': {
        short: 'Same as Above',
        full: 'Post Address Same As Pre',
      },
      'lease.hasPreConstruction': {
        short: 'Pre-Construction',
      },
      'lease.hasPremisesAreaSizeStipulated': {
        short: 'Premises Size Stipulated By Agreement',
        full: 'Stipulated Premises SF',
      },
      'lease.hasPriorAmendment': {
        short: 'Prior Amendments To Lease',
        full: 'Prior Amendment',
      },
      'lease.hasPriorAssignments': {
        short: 'Prior Assignments Of Lease',
        full: 'Prior Assignments',
      },
      'lease.hasTenantPriorAssignments': {
        short: 'Prior Tenant Assignments Of Lease',
      },
      'lease.hasLandlordPriorAssignments': {
        short: 'Prior Landlord Assignments Of Lease',
      },
      'lease.hasPromotionFund': {
        short: 'Promotion Fund',
      },
      'lease.hasPublicSecuritiesCarveout': {
        short: 'Public Securities Carveout',
      },
      'lease.hasPylonSign': {
        short: 'Pylon Sign',
      },
      'lease.hasPylonSignExhibit': {
        short: 'Upload Pylon Sign Exhibit',
      },
      'lease.pylonSign.url': {
        short: 'Pylon Sign Exhibit',
      },
      'lease.hasReasonableFutureSNDAEfforts': {
        short: 'Reasonable Efforts Only',
      },
      'lease.hasRadiusRestriction': {
        short: 'Radius Restrictions',
        full: 'Has Radius Restriction',
      },
      'lease.hasRecaptureWithRescission': {
        short: 'Landlord Recapture',
      },
      'lease.hasAssignmentFeeCap': {
        short: 'Assignment Review Fee Cap',
      },
      'lease.assignmentFeeCap': {
        short: 'Review Fee Cap',
      },
      'lease.hasReasonableAlterations': {
        short: 'Reasonable Alterations',
      },
      'lease.hasReleaseOfTenant': {
        short: 'Release of Tenant',
      },
      'lease.hasRelocation': {
        short: 'Relocation',
      },
      'lease.hasRelocationProhibitedInInitialTerm': {
        short: 'Relocation Prohibited In Initial Term',
      },
      'lease.hasRelocationOnWeekendsOnly': {
        short: 'Relocation on Weekends Only',
      },
      'lease.hasRenewalOption': {
        short: 'Renewal',
        full: 'Renewal Option(s)',
      },
      'lease.hasRenewalRentArbitration': {
        short: 'Add Arbitration Process',
      },
      'lease.hasRenewalRentCurrentRentFloor': {
        short: 'Current Rent Floor',
      },
      'lease.hasArrearage': {
        short: 'Arrearage',
      },
      'lease.arrearageType': {
        short: 'Arrearage Type',
      },
      'lease.arrearageType.waiver': {
        short: 'Waiver',
      },
      'lease.arrearageType.payment': {
        short: 'Payment',
      },
      'lease.arrearageDate': {
        short: 'Date',
      },
      'lease.arrearageAmount': {
        short: 'Amount',
      },
      'lease.arrearageAmount.unit': {
        short: '$',
      },
      'lease.arbitrationProcess.submarket': {
        short: 'Submarket for Comps',
      },
      'lease.hasRentAbatement': {
        short: 'Free Rent Dates',
        full: 'Rent Abatement',
      },
      'lease.hasReplacementSignageCriteria': {
        short: 'Replace Signage Criteria',
        full: 'Upload Signage Criteria',
      },
      'lease.hasReplacementSitePlan': {
        short: 'Replace Site Plan',
        full: 'Replacement Site Plan',
      },
      'lease.hasResetBaseYearForRenewal': {
        short: 'Reset Base Year for Renewal Term(s)',
      },
      'lease.hasResetBreakpoint': {
        short: 'Reset Breakpoint',
        full: 'Reset Percentage Rent Breakpoint',
      },
      'lease.hasRightOfFirstOffer': {
        short: 'Right of First Offer',
      },
      'lease.hasRofoContiguousSpaceLimitation': {
        short: 'Limit ROFO to Contiguous Space',
        full: 'Rolling Guaranty',
      },
      'lease.hasRollingGuaranty': {
        short: 'Rolling',
        full: 'Rolling Guaranty',
      },
      'lease.hasRooftopEquipment': {
        short: 'Rooftop Equipment',
      },
      'lease.hasSameBillingAddress': {
        short: 'Same as Above',
        full: 'Billing Address Same',
      },
      'lease.hasSatellite': {
        short: 'Permit Satellite Dish on Roof',
        full: 'Satellite Dish',
      },
      'lease.hasScaledHoldover': {
        short: 'Holdover Rent Penalty is Scaled',
        full: 'Scaled Holdover Penalty',
      },
      'lease.hasHoldoverFeeWaived': {
        short: 'Holdover Fee Waived',
        full: 'Holdover Fee Waived',
      },
      'lease.hasSecureAreas': {
        short: 'Secure Areas',
      },
      'lease.hasSecurityDepositBurndown': {
        short: 'Add L/C Burndown',
      },
      'lease.hasSecurityDeposit': {
        short: 'Security Deposit',
      },
      'lease.hasSignageDeposit': {
        short: 'Signage Deposit',
      },
      'lease.hasSecuritySystem': {
        short: 'Security System',
      },
      'lease.hasSignageRendering': {
        short: 'Signage Renderings',
        full: 'Signage Rendering',
      },
      'lease.hasSimultaneousDelivery': {
        short: 'Term Commences Upon Lease Signing',
        full: 'Simultaneous Delivery',
      },
      'lease.hasRestroomAllowance': {
        short: 'Restroom Allowance',
        full: 'Restroom Allowance',
      },
      'lease.hasDemolitionAllowance': {
        short: 'Demolition Allowance',
        full: 'Demolition Allowance',
      },
      'lease.hasSpacePlanningAllowance': {
        short: 'Space Planning Allowance',
        full: 'Space Planning Allowance',
      },
      'lease.hasSpecificTenant': {
        short: 'Specific Tenant',
        full: 'Specific Tenant',
      },
      'lease.restroomAllowance.amount': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.demolitionAllowance.amount': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.spacePlanningAllowance.amount': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.hasSNDA': {
        short: 'Landlord to Obtain an SNDA',
        full: 'SNDA',
      },
      'lease.spacePlanningAllowance.unit': {
        short: '$/Sf',
        full: '$/Sf',
      },
      'lease.hasSNDAForm': {
        short: 'Attach Form SNDA',
      },
      'lease.sndaForm.url': {
        short: 'SNDA Exhibit',
      },
      'lease.originalLeaseName': {
        short: "Lease Name",
      },
      'lease.offeringPlan.url': {
        short: 'Upload Offering Space Plan',
      },
      'lease.hasRofoOfferingSpacePlan': {
        short: 'Offering Space Plan',
      },
      'lease.temporarySpacePlan.url': {
        short: 'Upload Temporary Space Plan',
      },
      'lease.temporaryPremises.commencementDateType': {
        short: 'Commencement Date Type',
        full: 'Commencement Date Type',
      },
      'lease.temporaryPremises.commencementDate': {
        short: 'Commencement Date',
        full: 'Commencement Date',
      },
      'lease.hvacSpecs.url': {
        short: 'Upload HVAC Specs',
      },
      'lease.hasSpecialUseProvisions': {
        short: 'Special Use Provisions',
      },
      'lease.hasSpecificExpirationDate': {
        short: 'Specific Expiration Date',
      },
      'lease.hasSubordinateLandlordLien': {
        short: 'Subordinate Landlord Lien to Tenant Lender',
        full: 'Subordinate Landlord Lien',
      },
      'lease.hasSubleaseToOccupantPermitted': {
        short: 'Sublease to Occupant of Building Permitted'
      },
      'lease.hasTenantNoticeAddress': {
        short: 'Change Tenant Notice Address',
      },
      'lease.hasTenantDeliveriesInFrontOfBuilding': {
        short: 'Tenant May Receive Deliveries in Front of Building'
      },
      'lease.hasTenantDeliveriesInRearOfBuilding': {
        short: 'Tenant May Receive Deliveries in Rear of Building'
      },
      'lease.hasCrossDefault': {
        short: 'Cross Default'
      },
      'lease.hasWaiverJuryTrial': {
        short: 'Waiver Jury Trial'
      },
      'lease.hasGroundLease': {
        short: 'Ground Lease'
      },
      'lease.cb.deadlineForExpenseStatement': {
        short: 'Deadline for Expense Statement'
      },
      'lease.cb.deadlineForExpenseStatementPenaltyDeadline': {
        short: 'Penalty Deadline'
      },
      'lease.cb.hasShoppingCenterViolationContingency': {
        short: 'Shopping Center Violation Contingency'
      },
      'lease.cb.shoppingCenterViolationContingencyTenantUnableToObtain': {
        short: 'Tenant Unable To Obtain'
      },
      'lease.cb.hasShoppingCenterViolationContinencyRentCommencementDateExtension': {
        short: 'Rent Commencement Date Extension'
      },
      'lease.cb.hasSuiteSignageAtLLExpense': {
        short: 'Suite Signage at LL Expense'
      },
      'lease.cb.hasTenantNotRequiredToImproveCommonAreasForLaws': {
        short: 'Tenant Not Required to Improve Common Areas to Comply With Laws'
      },
      'lease.cb.hasTenantTerminationForCasualtyActualTimeReq': {
        short: 'Tenant Termination for Casualty - Actual Time Req.'
      },
      'lease.cb.hasExtensionOfTenantCurePeriod': {
        short: 'Extension of Tenant Cure Period'
      },
      'lease.cb.hasLandlordEstoppel': {
        short: 'Landlord Estoppel'
      },
      'lease.cb.hasLandlordEstoppelRequiredCertificationsSpecified': {
        short: 'Required Certification Specified'
      },
      'lease.cb.hasLimitationOnProjectModification': {
        short: 'Limitation on Project Modification'
      },
      'lease.cb.hasSpecifyHvacAhRate': {
        short: 'Specify HVAC A/H Rate'
      },
      'lease.cb.hvacAhRateAmount': {
        short: 'Amount'
      },
      'lease.cb.hasWaiverOfTenantLiabilityForDamages': {
        short: 'Waiver of Tenant Liability for Consequential Damages',
      },
      'lease.cb.elevatorService.has247Access': {
        short: '24/7 Elevator Access'
      },
      'lease.cb.elevatorService.hasFreeFreightElevator': {
        short: 'Free Freight Elevator'
      },
      'lease.cb.elevatorService.hasFreightAccess': {
        short: 'Access to Loading Dock and Freight Elevator'
      },
      'lease.hasTenantBuysFurniture': {
        short: "Tenant Purchasing Prior Occupant's Furniture",
        full: 'Tenant Buys Furniture',
      },
      'lease.hasTenantCasualtyTermination': {
        short: 'Tenant Termination for Casualty',
        full: 'Termination for Casualty',
      },
      'lease.hasTenantCompetitors': {
        short: 'Tenant Competitors',
        full: 'Tenant Competitors',
      },
      'lease.consentContingencies': {
        short: 'Consent Contingencies'
      },
      'lease.consentContingencies.name': {
        short: 'Tenant Name'
      },
      'lease.consentContingencies.nickname': {
        short: 'Tenant Nickname'
      },
      'lease.hasTenantCosmeticImprovements': {
        short: 'Tenant to Make Cosmetic Improvements',
        full: 'Tenant to Make Cosmetic Improvements',
      },
      'lease.hasTenantIncentiveAllowance': {
        short: 'Tenant Incentive Allowance',
        full: 'Tenant Incentive ALW',
      },
      'lease.hasTenantRescissionRight': {
        short: 'Tenant Rescission Right',
      },
      'lease.hasTenantRemeasurement': {
        short: 'Tenant Remeasurement Right',
      },
      'lease.hasTenantRemeasurementChallenge': {
        short: 'Tenant Challenge',
      },
      'lease.hasTenantTerminationOption': {
        short: 'Tenant Termination Option',
      },
      'lease.hasTenantToMakeCosmeticImprovements': {
        short: 'Tenant to Make Cosmetic Improvements'
      },
      'lease.hasTenancyAtSufferanceChange': {
        short: 'Change to Tenancy at Sufferance',
      },
      'lease.hasTiAllowance': {
        short: 'Tenant Improvement Allowance',
        full: 'Tenant Improvement ALW',
      },
      'lease.hasUploadTenantImprovementPlans': {
        short: 'Upload Tenant Improvement Plans',
      },
      'lease.hasUtilityInterruption': {
        short: 'Utility Interruption Compromise',
      },
      'lease.hvacCapAmount': {
        short: 'Cap Amount',
        full: 'HVAC Cap Amount',
      },
      'lease.hvacTenantCapAmount': {
        short: 'Amount',
        full: 'HVAC Tenant Cap Amount',
      },
      'lease.insuranceReimbursement': {
        short: 'Estimated Insurance',
        full: 'Insurance Reimbursement',
      },
      'lease.kickoutMinimumSales': {
        short: 'Minimum Sales',
        full: 'Kickout Minimum Sales',
      },
      'lease.kickoutTerminationFee': {
        short: 'Amount',
        full: 'Kickout Termination Fee',
      },
      'lease.kickoutTestingPeriodYears': {
        short: 'Testing Period',
        full: 'Kickout Testing Period',
      },
      'lease.landlordBroker': {
        short: 'Name',
        full: 'Landlord Broker',
      },
      'lease.landlordBrokerAddress': {
        short: 'Address',
        full: 'Address',
      },
      'lease.landlordBrokerCity': {
        short: 'City',
        full: 'City',
      },
      'lease.landlordBrokerState': {
        short: 'State',
        full: 'State',
      },
      'lease.landlordBrokerZip': {
        short: 'Zip',
        full: 'Zip',
      },
      'lease.landlordConstructionCap': {
        short: 'Amount',
      },
      'lease.landlordConstructionCapPerSqFt': {
        short: 'Amount',
      },
      'lease.landlordDoesConstruction': {
        short: 'Landlord Work',
      },
      'lease.hasLandlordLienSubordination': {
        short: 'Landlord Lien Subordination',
        full: 'Landlord Lien Subordination',
      },
      'lease.landlordLienSubordination.url': {
        short: 'Lien Subordination Exhibit',
        full: 'Lien Subordination Exhibit',
      },
      'lease.landlordPermittedUseRep': {
        short: 'Representation',
        full: 'Landlord Rep re: Permitted use',
      },
      'lease.landlordWarranty.electrical': {
        short: 'Electrical System',
      },
      'lease.landlordWarranty.hvac': {
        short: 'HVAC',
        full: 'Landlord HVAC Warranty',
      },
      'lease.landlordWarranty.plumbing': {
        short: 'Plumbing System',
      },
      'lease.landlordWarranty.roof': {
        short: 'Roof',
      },
      'lease.landlordWarranty.commonAreas': {
        short: 'Common Areas',
      },
      'lease.landlordWarranty.landlordContractor': {
        short: 'Landlord Contractor',
      },
      'lease.landlordWarranty.buildingSystems': {
        short: 'Building Systems',
      },
      'lease.landlordWorkDeliveryCondition': {
        short: 'Delivery Condition',
      },
      'lease.landlordWork.url': {
        short: 'Landlord Work Exhibit',
      },
      'lease.layoutPlan.url': {
        short: 'Upload Preliminary Plan - Schedule C-4',
        full: 'Layout Plan',
      },
      'lease.leasePlan.url': {
        short: 'Upload Lease Plan',
        full: 'Lease Plan',
      },
      'lease.leaseYears': {
        short: 'Term Length',
        full: 'Term Length (Years)',
      },
      'lease.letterOfCredit': {
        short: 'Delivery',
        full: 'Letter of Credit',
      },
      'lease.lenderInfo': {
        short: 'Lender Representative',
      },
      'lease.lenderInfo.name': {
        short: 'Lender Name',
      },
      'lease.lenderInfo.address': {
        short: 'Address',
      },
      'lease.lenderInfo.city': {
        short: 'City',
      },
      'lease.lenderInfo.state': {
        short: 'State',
      },
      'lease.lenderInfo.zip': {
        short: 'Zip',
      },
      'lease.minimumSales.value': {
        short: 'Year #{#}',
      },
      'lease.monetaryCapOnGuarantyAmount': {
        short: 'Amount',
        full: 'Monetary Cap Amount',
      },
      'lease.monetaryCapOnGuarantyPeriod': {
        short: 'Period',
        full: 'Monetary Cap Period',
      },
      'lease.monthToMonthEnd': {
        short: 'End of Month to Month Period',
        full: 'Month To Month End',
      },
      'lease.monthToMonthStart': {
        short: 'Beginning of Month to Month Period',
        full: 'Month to Month Start',
      },
      'lease.naturalExpirationDate': {
        short: 'Expiration Date',
        full: 'Natural Expiration Date',
      },
      'lease.noReleasePeriod': {
        short: 'Tenant Only Released After',
        full: 'No Release Until a/f Lease Year #',
      },
      'lease.officeMaintenanceBaseYear': {
        short: 'Office Maintenance Base Year',
      },
      'lease.ongoingCoTenancy.alternativeRent': {
        short: 'Alternate Rent Formula',
      },
      'lease.ongoingCoTenancy.grossSalesPercent': {
        short: 'Amount',
        full: '% of Gross Sales',
      },
      'lease.ongoingCoTenancy.anchorTenantClosurePeriod': {
        short: 'Closure Period',
      },
      'lease.ongoingCoTenancy.anchorTenantCurePeriod': {
        short: 'Cure Period',
      },
      'lease.ongoingCoTenancy.anchorTenant': {
        short: 'Name',
        full: 'Anchor Tenant',
      },
      'lease.ongoingCoTenancy.anchorTenantReqReopeningPercent': {
        short: 'Reopening Req. Min. Area',
        full: 'Reopening Required Minimum Area',
      },
      'lease.ongoingCoTenancy.baseRentPercent': {
        short: 'Amount',
      },
      'lease.ongoingCoTenancy.dropDeadDate': {
        short: 'Drop Dead Date',
        full: 'Drop Dead Date:',
      },
      'lease.ongoingCoTenancy.alternativeRent.grossSales': {
        short: '% of Gross Sales',
      },
      'lease.ongoingCoTenancy.alternativeRent.baseRent': {
        short: '% of Base Sales',
      },
      'lease.ongoingCoTenancy.minimumRentPercent': {
        short: 'Amount',
        full: '% of Minimum Rent',
      },
      'lease.ongoingCoTenancy.hasAnchor': {
        short: 'Anchor Tenant',
      },
      'lease.ongoingCoTenancy.hasNamedTenants': {
        short: 'Named Tenants',
      },
      'lease.ongoingCoTenancy.hasPercentGLA': {
        short: '% GLA Open',
      },
      'lease.ongoingCoTenancy.minimumOperatingPeriod': {
        short: 'Min. Operating Period',
        full: 'Minimum Operating Period:',
      },
      'lease.ongoingCoTenancy.minNamedTenants': {
        short: 'Minimum No.',
      },
      'lease.ongoingCoTenancy.minPercentAreaOpen': {
        short: 'Amount',
      },
      'lease.ongoingCoTenancy.namedTenantsClosurePeriod': {
        short: 'Closure Period',
      },
      'lease.ongoingCoTenancy.namedTenantsCurePeriod': {
        short: 'Cure Period',
      },
      'lease.ongoingCoTenancy.namedTenants': {
        short: 'List of Major Tenants',
        full: 'Named Tenants',
      },
      'lease.ongoingCoTenancy.namedTenantsReqReopeningPercent': {
        short: 'Reopening Req. Min. Area',
      },
      'lease.openingCoTenancy.namedTenantsReqReopeningPercent': {
        short: 'Reopening Req. Min. Area',
      },
      'lease.openingCoTenancy.alternativeRent': {
        short: 'Alternative Rent Formula',
        full: 'Alternate Rent Formula',
      },
      'lease.openingCoTenancy.grossSalesPercent': {
        short: 'Amount',
        full: '% of Gross Sales',
      },
      'lease.openingCoTenancy.anchorTenant': {
        short: 'Name',
        full: 'Anchor Tenant',
      },
      'lease.openingCoTenancy.baseRentPercent': {
        short: 'Amount',
        full: 'Base Rent %',
      },
      'lease.openingCoTenancy.dropDeadDateTenantHasNotOpened': {
        short: 'If Tenant is <b>Not</b> Open:',
        full: 'If Tenant is <b>Not</b> Open:',
      },
      'lease.openingCoTenancy.dropDeadDateTenantHasOpened': {
        short: 'If Tenant is Open:',
        full: 'If Tenant is Open:',
      },
      'lease.openingCoTenancy.grossSalesPercen': {
        short: 'Amount',
      },
      'lease.openingCoTenancy.hasAnchor': {
        short: 'Anchor Tenant',
      },
      'lease.openingCoTenancy.hasNamedTenants': {
        short: 'Named Tenants',
      },
      'lease.openingCoTenancy.hasPercentGLA': {
        short: '% GLA Open',
      },
      'lease.openingCoTenancy.minNamedTenants': {
        short: 'Minimum No.',
        full: 'Min Named Tenants',
      },
      'lease.openingCoTenancy.minPercentAreaOpen': {
        short: 'Amount',
      },
      'lease.openingCoTenancy.namedTenants': {
        short: 'List of Major Tenants',
        full: 'Named Tenants',
      },
      'lease.outdoorSeatingType': {
        short: 'Layout',
        full: 'Outdoor Seating Type',
      },
      'lease.outdoorSeatingAreaSize': {
        short: 'Area Size',
      },
      'lease.parkingAmount': {
        short: 'Number of Spaces',
        full: 'Parking Amount',
      },
      'lease.parkingCharge': {
        short: 'Parking Charge',
      },
      'lease.parkingAnnualEscalator': {
        short: 'Annual Escalation',
      },
      'lease.pctRentBaseGrossSales': {
        short: 'Base Gross Rent',
        full: 'Base Gross Sales',
      },
      'lease.pctRentHasNaturalBreakpoint': {
        short: 'Type of Breakpoint',
        full: 'Natural Breakpoint',
      },
      'lease.percentageRent': {
        short: 'Amount',
        full: 'Percentage Rent Amount',
      },
      'lease.percentageRentUnnaturalEscalation': {
        short: 'Amount',
        full: 'Unnatural Escalation Amount',
      },
      'lease.permittedUseDryCleaner': {
        short: 'Dry Cleaners',
      },
      'lease.permittedUseLiquorSales': {
        short: 'Liquor',
        full: 'Liquor Sales',
      },
      'lease.permittedUseMedical': {
        short: 'Medical',
      },
      'lease.permittedUseNailSalon': {
        short: 'Nail Salons',
      },
      'lease.permittedUseOdor': {
        short: 'Tenant to Prevent Smoke and Odors',
        full: 'Smoke/Odor',
      },
      'lease.permittedUseRestaurant': {
        short: 'Restaurant',
      },
      'lease.permittedUseFoodCourt': {
        short: 'Food Court',
      },
      'lease.permittedUseOutdoorDiningArea': {
        short: 'Outdoor Dining Area',
      },
      'lease.permittedUseParkingLotBin': {
        short: 'Parking Lot Bin',
      },
      'lease.permittedUsePetStore': {
        short: 'Pet Store',
      },
      'lease.permittedUses': {
        short: 'Description',
        full: 'Permitted Uses',
      },
      'lease.permittedUseFitness': {
        short: 'Fitness',
      },
      'lease.permittedUseWater': {
        short: 'Tenant to Prevent Water Leaks',
        full: 'Water',
      },
      'lease.planApprovalPeriod': {
        short: "Landlord's Response Period",
        full: 'Approval Period',
      },
      'lease.premiseAddressCity': {
        short: 'City',
        full: 'Premises City',
      },
      'lease.premiseAddress': {
        short: 'Premises Address',
      },
      'lease.premiseAddressState': {
        short: 'State',
        full: 'Premises State',
      },
      'lease.premiseAddressZip': {
        short: 'Zip',
        full: 'Premises ZIP',
      },
      'lease.premiseAreaSize': {
        short: 'Area Size',
      },
      'lease.premisesFloorNumber': {
        short: 'Suite Floor',
        full: 'Premises Floor Number',
      },
      'lease.hasPremisesPlan': {
        short: 'Upload Premises Plan',
        full: 'Premises Plan',
      },
      'lease.premisesPlan.url': {
        short: 'Upload Premises Plan',
        full: 'Premises Plan',
      },
      'lease.premiseStoreroomNumber': {
        short: 'Space Number',
        full: 'Space No.',
      },
      'lease.previousAmendment.date': {
        short: 'Amendment No. {#} Date',
        // full: "Previous Amendment Date"
      },
      'lease.previousAmendment.name': {
        short: 'Amendment No. {#} Name',
        // full: "Previous Amendment Name"
      },
      'lease.previousAmendment.length': {
        short: 'Number of Prior Amendments',
      },
      'lease.priorAssignments.assignor': {
        short: 'Assignor Name',
      },
      'lease.priorAssignments.tenantAssignor': {
        short: 'Assignor Name',
      },
      'lease.priorAssignments.landlordAssignor': {
        short: 'Assignor Name',
      },
      'lease.priorAssignments.date': {
        short: 'Assignment No. {#} Date',
        // full: "Prior Assignments Date"
      },
      'lease.priorAssignments.type': {
        short: 'Assignment Type',
      },
      'lease.priorAssignments.stateOfFormation': {
        short: 'State of Formation',
      },
      'lease.priorAssignments.tenantType': {
        short: 'Tenant Type',
      },
      'lease.priorAssignments.entityType': {
        short: 'Entity Type',
      },
      'lease.priorAssignments.tenantType.entity': {
        short: 'Entity',
      },
      'lease.priorAssignments.tenantType.individual': {
        short: 'Individual',
      },
      'lease.priorAssignments.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.priorAssignments.entityType.limitedLiabilityCompany': {
        short: 'Limited Liability Company',
      },
      'lease.priorAssignments.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.priorAssignments.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.priorAssignments.entityType.professionalCorporation': {
        short: 'Professional Corporation',
      },
      'lease.priorAssignments.entityType.governmentEntity': {
        short: 'Government Entity',
      },
      'lease.priorAssignments.entityType.other': {
        short: 'Other',
      },
      'lease.priorAssignments.entityTypeOther': {
        short: 'Entity Type (Other)',
      },
      'lease.promotionFundCharge': {
        short: 'Promotion Fund Amount',
      },
      'lease.proportionateShare': {
        short: 'Proportionate Share',
      },
      'lease.proporShareBreakdown.cam': {
        short: 'Proportionate Share of CAM',
      },
      'lease.proporShareBreakdown.taxes': {
        short: 'Proportionate Share of Real Estate Taxes',
      },
      'lease.proporShareBreakdown.officeMaintenance': {
        short: 'Proportionate Share of Office Maintenance Expenses',
      },
      'lease.radiusRestriction': {
        short: 'Distance',
        full: 'Radius Restrictions',
      },
      'lease.realEstateReimbursement': {
        short: 'Estimated Real Estate Taxes',
        full: 'Est. Real Estate Tax',
      },
      'lease.snowIceRemovalReimbursement': {
        short: 'Estimated Snow/Ice Removal',
      },
      'lease.buildingSecurityReimbursement': {
        short: 'Estimated Building Security',
      },
      'lease.recapture.surrenderAreaSize': {
        short: 'Surrender Space Area Size',
      },
      'lease.recapture.remainingAreaSize': {
        short: 'Remaining Premises Area Size',
      },
      'lease.reduction.termYears': {
        short: 'Reduction Term Length',
      },
      'lease.reduction.hardCommencementDate': {
        short: 'Reduction Commencement Date',
      },
      'lease.reductionPeriods.amountReduced': {
        short: 'Amount Reduced To:',
        full: 'Amount Reduced To',
      },
      'lease.reductionPeriods.date': {
        short: 'Reduction Date No. {#}',
        full: 'Reduction Date',
      },
      'lease.reductionPeriods.year': {
        short: 'Reduction Date No. {#}',
        full: 'Reduction Date',
      },
      'lease.reductionPeriodsNumber': {
        short: 'No. of Reduction Periods',
      },
      'lease.renewalCount': {
        short: 'Number of Renewal Terms',
      },
      'lease.renewalInfo.cpiAnchorYears': {
        short: 'Years prior to CPI',
        full: 'Renewal CPI Anchor Years',
      },
      'lease.renewalInfo.hasCpiRent': {
        short: 'CPI',
        full: 'Renewal CPI',
      },
      'lease.renewalInfo.renewalAnnualIncrease': {
        short: 'Renewal Annual Increase',
      },
      'lease.renewalInfo.renewalBaseRent': {
        short: 'Renewal Base Rent',
      },
      'lease.renewalInfo.renewalRentPeriod': {
        short: 'Renewal Term',
      },
      'lease.renewalInfo.renewalRentPeriods': {
        short: 'Renewal Term No. {#}',
      },
      'lease.renewalInfo.renewalBaseRent.unit': {
        short: '$/Sf',
      },
      'lease.renewalInfo.renewalRentPeriods.rentPerSqFoot.unit': {
        short: '$/Sf',
      },
      'lease.renewalInfo.renewalInputType': {
        short: 'Base Rent - Extended Term {#}',
        full: 'Renewal Input Type',
      },
      'lease.renewalInfo.renewalOptionRent': {
        short: 'Method to Set Renewal Rent',
        full: 'Method to Set Renewal Rent',
      },
      'lease.renewalInfo.renewalRentPeriods.rentPerSqFoot': {
        short: 'Year {#}',
        full: 'Renewal Rent Periods Per Sq Foot',
      },
      'lease.renewalInfo.renewalRentPeriods.totalRent': {
        short: 'Year {#}',
      },
      'lease.renewalOptionRent': {
        short: '',
      },
      'lease.renewalOptionRentPercentage': {
        short: 'Percent of Fair Market Value',
      },
      'lease.renewalTerm': {
        short: 'Renewal Term Length',
      },
      'lease.renewalTermNoticeType': {
        short: 'Notice Required',
      },
      'lease.renewalTermNotice.rangePeriodEarliestLength': {
        short: 'Earliest Notice',
      },
      'lease.renewalTermNotice.rangePeriodLongestLength': {
        short: 'Latest Notice',
      },
      'lease.renewalTermNotice.statedPeriodLength': {
        short: '',
      },
      'lease.renewalTermNotice.statedPeriodLength.unit': {
        short: 'Month(s)',
      },
      'lease.rentCommenceAt': {
        short: 'Delivery Date',
      },
      'lease.rentInputType': {
        short: 'Base Rent - Term {#}',
        full: 'Rent Input Type',
      },
      'lease.rentPeriods.rentPerSqFoot': {
        short: 'Year {#}',
        full: 'Rent Per Sq Foot',
      },
      'lease.rentPeriods.rentPerSqFoot.unit': {
        short: '$/Sf',
        full: '$/Sf',
      },
      'lease.rentPeriods.totalRent': {
        short: 'Year {#}',
      },
      'lease.hasUpdateLandlordAddress': {
        short: 'Update Landlord\'s Address',
      },
      'lease.hasOpeningCovenant': {
        short: 'Opening Covenant',
      },
      'lease.requiredOpening.days': {
        short: 'Required Opening',
        full: 'Days After',
      },
      'lease.requiredOpening.from': {
        short: 'Required Opening After',
        full: 'Required Opening Date From',
      },
      'lease.rightOfFirstOffer.hasCoTerminus': {
        short: 'ROFO Term Co-terminus with Lease',
        full: 'Co Terminus',
      },
      'lease.rightOfFirstOffer.hasVacant': {
        short: 'ROFO Space is Currently Vacant',
      },
      'lease.rightOfFirstOffer.lateTermPeriod': {
        short: 'ROFO Invalid if Would Occur in The Last',
        full: 'Late Term Period',
      },
      'lease.rightOfFirstOffer.remainingTermRequiredForCoTerminus': {
        short: 'Remaining Lease Term Requiring Co-termination',
        full: 'Remaining Term Required For Co Terminus',
      },
      'lease.rightOfFirstOffer.areaSize': {
        short: 'Area Size',
      },
      'lease.rightOfFirstOffer.spaces': {
        short: 'Spaces Subject to ROFO',
        full: 'Spaces',
      },
      'lease.rightOfFirstOffer.hasSuperiorRightsHolders': {
        short: 'Superior Rights Holders',
      },
      'lease.superiorRightsHolders.url': {
        short: 'Upload Superior Rights Holders',
      },
      'lease.hasOptionalUpload': {
        short: 'Optional Upload',
      },
      'lease.optionalUpload.url': {
        short: 'Upload Optional Upload',
      },
      'lease.tenantsMenu.url': {
        short: 'Upload Tenant\'s Menu',
      },
      'lease.hasPatioPlan': {
        short: 'Patio Plan',
      },
      'lease.patioPlan.url': {
        short: 'Upload Patio Plan',
      },
      'lease.rollingGuarantyPeriod': {
        short: 'Period after Event of Default',
        full: 'Rolling Guaranty Period',
      },
      'lease.rollingGuarantyMeasuringPeriod': {
        short: 'Measuring Period',
      },
      'lease.security.cashAmount': {
        short: 'Cash Amount',
      },
      'lease.security.locAmount': {
        short: 'LoC Amount',
      },
      'lease.security.hasCashBurndown': {
        short: 'Add Burndown',
      },
      'lease.security.hasLocBurndown': {
        short: 'Add Burndown',
      },
      'lease.security.cashBurndownPeriodsNumber': {
        short: 'No. of Reduction Periods',
      },
      'lease.security.locBurndownPeriodsNumber': {
        short: 'No. of Reduction Periods',
      },
      'lease.security.cashBurndownPeriods.date': {
        short: 'Reduction Date No. {#}',
        full: 'Reduction Date',
      },
      'lease.security.locBurndownPeriods.date': {
        short: 'Reduction Date No. {#}',
        full: 'Reduction Date',
      },
      'lease.security.cashBurndownPeriods.amountReduced': {
        short: 'Amount Reduced To:',
        full: 'Amount Reduced To',
      },
      'lease.security.locBurndownPeriods.amountReduced': {
        short: 'Amount Reduced To:',
        full: 'Amount Reduced To',
      },
      'lease.securityDeposit': {
        short: 'Amount',
      },
      'lease.securityDepositType': {
        short: 'Type',
        full: 'Security Deposit Type',
      },
      'lease.securityDepositUpdate.existingAmount': {
        short: 'Existing Amount',
      },
      'lease.securityDepositUpdate.amendmentAmount': {
        short: 'Amendment Amount',
      },
      'lease.securityDepositUpdate.hasReplaceLCtoCash': {
        short: 'Convert LoC to Cash',
      },
      'lease.securityDepositUpdate.lcToCashAmount': {
        short: 'Cash Amount',
      },
      'lease.securityDepositUpdate.hasRenewLC': {
        short: 'Renew LoC',
      },
      'lease.securityDepositUpdate.renewLCAmount': {
        short: 'LoC Expiration Date',
      },
      'lease.signageCriteria.url': {
        short: 'Upload Signage Criteria',
        full: 'Signage Criteria',
      },
      'lease.signageDeposit': {
        short: 'Amount',
      },
      'lease.signageRendering.url': {
        short: 'Upload Signage Renderings',
        full: 'Signage Rendering',
      },
      'lease.sitePlan.url': {
        short: 'Upload Site Plan',
        full: 'Site Plan',
      },
      'lease.softEstimatedDeliveryDate': {
        short: 'Period After Lease Execution',
        full: 'Soft Estimated Delivery Date',
      },
      'lease.softTerminationDate': {
        short: 'Termination Date',
        full: 'Soft Termination Date',
      },
      'lease.tenantAttorney.name': {
        short: 'Name',
      },
      'lease.tenantAttorney.telephone': {
        short: 'Telephone',
      },
      'lease.tenantAttorney.email': {
        short: 'Email',
      },
      'lease.hasTenantCertificateLiabilityInsurance': {
        short: 'Attach Tenant Certificate of Liability Insurance',
      },
      'lease.tenantCertificateLiabilityInsurance': {
        short: 'Upload Tenant Certificate of Liability Insurance',
        full: 'Upload Tenant Certificate of Liability Insurance',
      },
      'lease.tenantCompetitors.hasAffiliates': {
        short: 'Include Competitor Affiliates',
        full: 'Include Competitor Affiliates',
      },
      'lease.tenantCompetitors.minAmount': {
        short: 'Min. Area',
        full: 'Min. Area',
      },
      'lease.tenantCompetitors.requiredOccupancy': {
        short: 'Required Occupancy',
        full: 'Required Occupancy',
      },
      'lease.tenantTerminationType': {
        short: 'Type of Termination Right',
        full: 'Type of Termination Right',
      },
      'lease.hardOptionStartDate': {
        short: 'Option Start Date',
        full: 'Option Start Date',
      },
      'lease.taxBaseYear': {
        short: 'Tax Base Year',
      },
      'lease.taxBaseYearType': {
        short: 'Tax Year Period',
        full: 'Tax Base Year Type',
      },
      'lease.tenantArchitect': {
        short: "Tenant's Architect",
        full: 'Tenant Architect',
      },
      'lease.tenantDoesConstruction': {
        short: 'Tenant Work',
        full: 'Tenant Construction',
      },
      'lease.tenantImprovementPlan.url': {
        short: 'Upload Tenant Improvement - Schedule c-2',
        full: 'Tenant Improvement Plan',
      },
      'lease.tenantImprovements.workType': {
        short: 'Improvements Type',
        full: 'Improvements Type',
      },
      'lease.tenantImprovements.workType.minor': {
        short: 'Minor',
        full: 'Minor',
      },
      'lease.tiAllowance.tiTotalAmount': {
        short: 'Total Amount',
        full: 'Total Amount',
      },
      'lease.tiAllowance.tiTotalAmount.unit': {
        short: '$',
        full: '$',
      },
      'lease.tenantImprovements.workType.major': {
        short: 'Major',
        full: 'Major',
      },
      'lease.tenantImprovements.landlordCapAmount': {
        short: 'Amount',
        full: 'Landlord Cap Amount',
      },
      'lease.tenantImprovements.payment': {
        short: 'Landlord Financial Contribution:',
        full: 'Payment',
      },
      'lease.tenantImprovementsAllowance.unit': {
        short: '$/Sf',
        full: '$/Sf',
      },
      'lease.tenantImprovements.type': {
        short: 'Construction of Tenant Improvements',
      },
      'lease.tenantImprovements.hasSubcontractBids': {
        short: 'Subcontract Bids',
      },
      'lease.tenantIncentiveAllowanceAmount': {
        short: 'Amount',
        full: 'Incentive Allowance Amount',
      },
      'lease.hasTenantBlanketInsurance': {
        short: 'Tenant Blanket Insurance',
      },
      'lease.hasTenantSelfInsurance': {
        short: 'Tenant Self-Insurance',
      },
      'lease.hasGracePeriodCompromise': {
        short: 'Late Charge Grace Period Compromise',
      },
      'lease.tenantInfo.hasCopyTo': {
        short: 'Send Multiple Notices To:',
      },
      'lease.tenantInfo.hasLandlordAgreesToPayTenantBroker': {
        short: 'Landlord Agrees to Pay Tenant\'s Broker',
      },
      'lease.tenantInfo.address1': {
        short: 'Address',
      },
      'lease.tenantInfo.addressCity': {
        short: 'City',
      },
      'lease.tenantInfo.addressState': {
        short: 'State',
      },
      'lease.tenantInfo.addressZip': {
        short: 'Zip',
      },
      'lease.tenantInfo.attention': {
        short: 'Attention',
      },
      'lease.tenantInfo.billingAddress1': {
        short: 'Address',
      },
      'lease.tenantInfo.billingAddressCity': {
        short: 'City',
      },
      'lease.tenantInfo.billingAddressState': {
        short: 'State',
      },
      'lease.tenantInfo.billingAddressZip': {
        short: 'Zip',
      },
      'lease.tenantInfo.billingAttention': {
        short: 'Attention',
      },
      'lease.tenantInfo.billingPhoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantInfo.billingEmail': {
        short: 'Email',
      },
      'lease.tenantInfo.broker': {
        short: 'Name',
        full: 'Tenant Broker Name',
      },
      'lease.tenantInfo.copyTo': {
        short: 'Notice Copies',
      },
      'lease.tenantInfo.copyTo.name': {
        short: 'Name',
      },
      'lease.tenantInfo.copyTo.address': {
        short: 'Address',
      },
      'lease.tenantInfo.copyTo.addressCity': {
        short: 'City',
      },
      'lease.tenantInfo.copyTo.addressState': {
        short: 'State',
      },
      'lease.tenantInfo.copyTo.addressZip': {
        short: 'Zip',
      },
      'lease.tenantInfo.copyTo.phoneNumber': {
        short: 'Phone',
      },
      'lease.tenantInfo.copyTo.email': {
        short: 'Email',
      },
      'lease.tenantInfo.copyTo.attention': {
        short: 'Attention',
      },
      'lease.tenantInfo.copyToAddress': {
        short: 'Address',
      },
      'lease.tenantInfo.copyToAddressCity': {
        short: 'City',
      },
      'lease.tenantInfo.copyToAddressState': {
        short: 'State',
      },
      'lease.tenantInfo.copyToAddressZip': {
        short: 'Zip',
      },
      'lease.tenantInfo.copyToAttention': {
        short: 'Attention',
      },
      'lease.tenantInfo.copyToEmail': {
        short: 'Email',
      },
      'lease.tenantInfo.copyToName': {
        short: 'Name',
      },
      'lease.tenantInfo.copyToPhoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantInfo.email': {
        short: 'Email',
      },
      'lease.tenantInfo.emergencyEmail': {
        short: 'Email',
        full: 'Emergency Contact Email',
      },
      'lease.tenantInfo.emergencyName': {
        short: 'Name',
        full: 'Emergency Contact Name',
      },
      'lease.tenantInfo.emergencyPhoneNumber': {
        short: 'Phone Number',
        full: 'Emergency Contact Phone',
      },
      'lease.tenantInfo.entityType': {
        short: 'Entity Type',
        full: 'Tenant Entity Type',
      },
      'lease.tenantInfo.entityTypeOther': {
        short: 'Entity Type (Other)',
        full: 'Tenant Entity Type (Other)',
      },
      'lease.tenantInfo.hasBroker': {
        short: 'Tenant\'s Broker',
      },
      'lease.tenantInfo.hasPublicCompany': {
        short: 'Public Company',
      },
      'lease.tenantInfo.individuals': {
        short: 'Tenant(s)',
      },
      'lease.assigneeInfo.individuals': {
        short: 'Assignee(s)',
      },
      'lease.hasAdditionalIndividualAssignees': {
        short: 'Additional Assignees',
        full: 'Add Additional Individual Assignee(s)',
      },
      'lease.hasAlternateSignature': {
        short: 'Change Signatory',
      },
      'lease.assigneeInfo.individuals.name': {
        short: 'Assignee Name {#}',
      },
      'lease.assigneeInfo.individuals.SSN': {
        short: 'Social Security No {#}.',
      },
      'lease.hasAdditionalIndividualTenants': {
        short: 'Additional Individuals',
        full: 'Add Additional Individual Tenant(s)',
      },
      'lease.tenantInfo.individuals.name': {
        short: 'Tenant Name {#}',
      },
      'lease.tenantInfo.individuals.SSN': {
        short: 'Social Security No {#}.',
      },
      'lease.tenantInfo.name': {
        short: 'Tenant Name',
      },
      'lease.tenantInfo.phoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantInfo.postLeaseAddress': {
        short: 'Address',
        full: 'Post Lease Address',
      },
      'lease.tenantInfo.postLeasePhoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantInfo.postLeaseEmail': {
        short: 'Email',
      },
      'lease.tenantInfo.postLeaseAttention': {
        short: 'Attention',
        full: 'Post Lease Attention',
      },
      'lease.tenantInfo.postLeaseCity': {
        short: 'City',
        full: 'Post Lease City',
      },
      'lease.tenantInfo.postLeaseState': {
        short: 'State',
        full: 'Post Lease State',
      },
      'lease.tenantInfo.postLeaseZipCode': {
        short: 'Zip',
        full: 'Post Lease Zip Code',
      },
      'lease.tenantInfo.preLeaseAddress': {
        short: 'Address',
        full: 'Pre Lease Address',
      },
      'lease.tenantInfo.preLeasePhoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantInfo.preLeaseEmail': {
        short: 'Email',
      },
      'lease.tenantInfo.preLeaseAttention': {
        short: 'Attention',
        full: 'Pre Lease Attention',
      },
      'lease.tenantInfo.preLeaseCity': {
        short: 'City',
        full: 'Pre Lease City',
      },
      'lease.tenantInfo.preLeaseState': {
        short: 'State',
        full: 'Pre Lease State',
      },
      'lease.tenantInfo.preLeaseZipCode': {
        short: 'Zip',
        full: 'Pre Lease ZipCode',
      },
      'lease.tenantInfo.ssn': {
        short: 'Social Security No.',
      },
      'lease.tenantInfo.stateOfFormation': {
        short: 'State Formation',
        full: 'Tenant State of Formation',
      },
      'lease.tenantInfo.taxId': {
        short: 'Tax ID',
        full: 'Tenant Tax ID',
      },
      'lease.tenantInfo.tradeName': {
        short: 'Tenant Trade Name',
      },
      'lease.tenantInfo.type': {
        short: 'Tenant Info',
        full: 'Tenant Type',
      },
      'lease.tenantInfo.brokerAddress': {
        short: 'Address',
      },
      'lease.tenantInfo.brokerCity': {
        short: 'City',
      },
      'lease.tenantInfo.brokerState': {
        short: 'State',
      },
      'lease.tenantInfo.brokerZip': {
        short: 'Zip',
      },
      'lease.tenantLiabilityInsuranceAmount': {
        short: 'Amount',
      },
      'lease.tenantPlanSubmissionDate': {
        short: 'Tenant Plan Submission Date',
      },
      'lease.hasTenantAccessToRisers': {
        short: 'Tenant Access to Risers',
      },
      'lease.hasTenantPermitContingency': {
        short: 'Permit Contingency',
      },
      'lease.tenantPermitContingency.applicationPeriod': {
        short: 'Application Period',
      },
      'lease.tenantPermitContingency.applicationDeadline': {
        short: 'Application Deadline',
      },
      'lease.tenantPermitContingency.hasExtension': {
        short: 'Extension Option',
      },
      'lease.tenantRepresentative': {
        short: 'Tenant Representative',
      },
      'lease.hasRemainingPortionOfPremisesUnusable': {
        short: 'Remaining Portion of Premises Unusable',
      },
      'lease.hasRmbsNotToExceed100Percent': {
        short: 'RMBS Not to Exceed 100%',
      },
      'lease.hasTenantRep': {
        short: 'Has Tenant Rep',
      },
      'lease.tenantRep.name': {
        short: 'Name',
      },
      'lease.tenantRep.address': {
        short: 'Address',
      },
      'lease.tenantRep.city': {
        short: 'City',
      },
      'lease.tenantRep.state': {
        short: 'State',
      },
      'lease.tenantRep.zipCode': {
        short: 'Zip',
      },
      'lease.tenantRep.phoneNumber': {
        short: 'Phone Number',
      },
      'lease.tenantRep.email': {
        short: 'Email Address',
      },
      'lease.tenantTerminationOptionFee': {
        short: 'Termination Fee',
      },
      'lease.tenantTerminationOption.hasCapOnFee': {
        short: 'Cap on Termination Fee',
      },
      'lease.tenantTerminationOption.capOnFee': {
        short: 'Amount',
      },
      'lease.rentAbatementExpiration': {
        short: 'Rent Abatement Expiration',
        full: 'Rent Abatement Expiration',
      },
      'lease.tenantTerminationOption.hasTerminationAmount': {
        short: 'Termination Fee Amount',
      },
      'lease.tenantTerminationOption.terminationAmount': {
        short: 'Amount',
      },
      'lease.termCommencementDate': {
        short: 'Term Commencement Date',
      },
      'lease.termNumber': {
        short: 'Extended Term No.',
        full: 'Term Number',
      },
      'lease.landlordAttorney.name': {
        short: 'Name',
      },
      'lease.landlordAttorney.telephone': {
        short: 'Telephone',
      },
      'lease.landlordAttorney.email': {
        short: 'Email',
      },
      'lease.landlordLateDeliveryPenalty.outsideDate': {
        short: 'Outside Delivery Date',
        full: 'Outside Delivery Date',
      },
      'lease.landlordLateDeliveryPenalty.deliveryDate': {
        short: 'Date Landlord Delivers Premises',
        full: 'Date Landlord Delivers Premises',
      },
      'lease.landlordLateDeliveryPenalty.estimatedDeliveryDate': {
        short: 'Delivery Date Without Tenant Delays',
        full: 'Delivery Date Without Tenant Delays',
      },
      'lease.landlordLateDeliveryPenalty.hasAbatement': {
        short: 'Abatement'
      },
      'lease.landlordLateDeliveryPenalty.hasTenantTerminationRight': {
        short: 'Tenant Termination Right'
      },
      'lease.tiAllowance.method': {
        short: 'Method',
        full: 'Method',
      },
      'lease.tiAllowance.amount': {
        short: 'Amount',
        full: 'TI Allowance Amount',
      },
      'lease.tiAllowance.amountType': {
        short: 'Amount Type',
      },
      'lease.tiAllowance.amountType.psf': {
        short: '$/square foot',
      },
      'lease.tiAllowance.amountType.dollarAmount': {
        short: '$ Total',
      },
      'lease.tiAllowance.amount.unit': {
        short: '$/Sf',
      },
      'lease.tiAllowance.totalAmount': {
        short: 'Amount',
        full: 'TI Allowance Amount',
      },
      'lease.tiAllowance.totalAmount.unit': {
        short: '$',
      },
      'lease.tiAllowance.minimumImprovementAmount': {
        full: 'Minimum Improvement Amount',
      },
      'lease.tiAllowance.minimumImprovementAmount.unit': {
        short: '$',
      },
      'lease.tiAllowance.grossAmount.unit': {
        short: '$/Sf',
      },
      'lease.tiAllowance.drawType': {
        short: 'Number of Draws',
        full: 'Draw Type',
      },
      'lease.tiAllowance.grossAmount': {
        short: 'Amount',
        full: 'TI Allowance Gross',
      },
      'lease.tiAllowance.hasRelocationPortion': {
        short: 'Portion Used for Relocation and Cabling',
      },
      'lease.tiAllowance.hasRentPortion': {
        short: 'Portion Used for Rent',
      },
      'lease.tiAllowance.hasTenantManagesConstruction': {
        short: 'Tenant Manages Construction',
      },
      'lease.tiAllowance.hasWaiverOfBonds': {
        short: 'Waiver of Bonds',
      },
      'lease.tiAllowance.hasSupplementalImprovementAllowance': {
        short: 'Supplemental Improvement Allowance',
      },
      'lease.tiAllowance.supplementalImprovementAllowance': {
        short: 'Amount',
        full: 'Supplemental Improvement Allowance Amount',
      },
      'lease.tiAllowance.relocationPortion': {
        short: 'Amount',
      },
      'lease.tiAllowance.rentPortion': {
        short: 'Amount',
      },
      'lease.tiAllowance.relocationPortion.unit': {
        short: '$/Sf',
      },
      'lease.tiAllowance.rentPortion.unit': {
        short: '$/Sf',
      },
      'lease.tiAllowance.hasDropDeadDate': {
        short: 'TI Drop Dead Date',
        full: 'TI Drop Dead Date',
      },
      'lease.tiAllowance.dropDeadDate': {
        short: 'Drop Dead Date',
        full: 'Drop Dead Date',
      },
      'lease.tiAllowance.dropDeadDateSoft': {
        short: 'Number of Months',
        full: 'Number of Months',
      },
      'lease.tiAllowance.dropDeadDateType': {
        short: 'Drop Dead Date Type',
      },
      'lease.tiAllowance.hasEquipmentPortion': {
        short: 'Portion Used for Equipment and Moving',
        full: 'Portion Used for Equipment and Moving',
      },
      'lease.tiAllowance.equipmentPortion': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.umbrellaLimit': {
        short: "Tenant's Umbrella Insurance Limit",
        full: 'Umbrella Limit',
      },
      'lease.utilitiesCharge': {
        short: 'Utilities Reimbursements',
        full: 'Utilities Charge Option',
      },
      'lease.utilitiesFlatCharge': {
        short: 'Amount',
        full: 'Utilities Flat Charge',
      },
      'lease.whenPlansCompleted': {
        short: 'Plans completed:',
        full: 'When Plans Completed',
      },
      'lease.whoCompletesPlans': {
        short: 'Plans completed by',
        full: 'Who Completes Plans',
      },
      'title.minimumRentRenewalTerms': {
        short: 'Minimum Rent - Renewal Term(s)',
      },
      'title.guarantorInfo': {
        short: 'Guarantor Info:',
      },
      'title.minimumSales': {
        short: 'Minimum Sales',
      },
      'title.minimumSalesRenewal': {
        short: 'Minimum Sales - Renewal {#}',
      },
      'lease.multipleIndividual': {
        short: 'Multiple Individual Tenants',
      },
      'lease.ongoingCoTenancy.namedTenantsList.name': {
        short: 'Tenant {#} Name',
      },
      'lease.ongoingCoTenancy.namedTenantsList.reqMinArea': {
        short: 'Tenant {#} Req. Min. Area',
      },
      'lease.ongoingCoTenancy.namedTenantsList': {
        short: 'Tenant',
      },
      'lease.hasReduction': {
        short: 'Reduction',
      },
      'lease.hasReductionFee': {
        short: 'Reduction Fee',
      },
      'lease.reductionFeeAmount': {
        short: 'Amount',
      },
      'lease.reductionPremisesFloor': {
        short: 'Reduction Premises Floor',
      },
      'lease.reductionPremisesSpaceNumber': {
        short: 'Reduction Premises Suite',
      },
      'lease.reductionAreaSize': {
        short: 'Surrender Space Area Size',
      },
      'lease.reductionRemainingAreaSize': {
        short: 'Remaining Premises Area Size',
      },
      'lease.reduction.extensionTermType': {
        short: 'Extension of Term Type',
      },
      'lease.reduction.expirationDate': {
        short: 'Expiration Date',
      },
      'lease.remainingPremises.url': {
        short: 'Upload Remaining Premises Area',
      },
      'lease.relocation.termYears': {
        short: 'Relocation Term Length',
      },
      'lease.relocation.commencementDateType': {
        short: 'Relocation Commencement Type',
      },
      'lease.relocation.hardCommencementDate': {
        short: 'Relocation Commencement Date',
      },
      'lease.relocation.hasFloatingCommencementDate': {
        short: 'Floating Commencement Date',
      },
      'lease.relocation.softCommencementDate': {
        short: 'Period',
      },
      'lease.relocation.extensionTermType': {
        short: 'Extension of Term Type',
      },
      'lease.relocation.expirationDate': {
        short: 'Expiration Date',
      },
      'lease.relocationPremisesFloor': {
        short: 'Relocation Premises Floor',
      },
      'lease.relocationPremisesAreaSize': {
        short: 'Relocation Premises Area Size',
      },
      'lease.relocationPremisesSpaceNumber': {
        short: 'Relocation Premises Space',
      },
      'lease.relocationExhibit.url': {
        short: 'Relocation Exhibit',
      },
      'lease.expansion.termYears': {
        short: 'Expansion Term Length',
      },
      'lease.expansion.extensionTermType': {
        short: 'Extension of Term Type',
      },
      'lease.expansion.expirationDate': {
        short: 'Expiration Date',
      },
      'lease.expansion.commencementDateType': {
        short: 'Expansion Commencement Type',
      },
      'lease.expansion.hardCommencementDate': {
        short: 'Expansion Commencement Date',
      },
      'lease.expansion.hasFloatingCommencementDate': {
        short: 'Floating Commencement Date',
      },
      'lease.expansion.softCommencementDate': {
        short: 'Period',
      },
      'lease.expansionPremiseFloor': {
        short: 'Expansion Premise Floor',
      },
      'lease.permitContingencyType': {
        short: 'Permit Contingency Type',
      },
      'lease.premisesModType': {
        short: 'Premises Modification Type',
      },
      'lease.premisesModTypeTerminationChangeBaseYearMessage': {
        short: 'Change Base Year Not Applicable in a Termination',
      },
      'lease.premisesModTypeRelocationChangeBaseYearMessage': {
        short: 'Change Base Year Not Applicable in a Relocation',
      },
      'lease.premisesModTypeTerminationTermMessage': {
        short: 'Extended Term Not Applicable in a Termination',
      },
      'lease.rentDeferralTermMessage': {
        short: 'Rent Table Not Applicable in a Rent Deferral',
      },
      'lease.premisesModTypeTerminationRentMessage': {
        short: 'Rent Table Not Applicable in a Termination',
      },
      'lease.termLengthNotApplicableWhenMonthToMonthMessage': {
        short: 'Term Length Not Applicable When Month-To-Month',
      },
      'lease.hiddenWhenNoPremModNoExtensionMessage': {
        short: 'Not Applicable When No Premises Modification and No Extension Of Term',
      },
      'lease.hiddenWhenNoChangeToTenantNoticeAddress': {
        short: 'Not Applicable When No Change to Tenant Notice Address',
      },
      'lease.hiddenWhenNoPremMod': {
        short: 'Not Applicable When No Premises Modification',
      },
      'lease.hiddenWhenNoExtensionAndNoRenewalOption': {
        short: 'Not Applicable When No Extension Of Term and no Renewal Option',
      },
      'lease.permittedUseNoise': {
        short: 'Tenant to Prevent Noise',
      },
      'lease.salesReportingFreq': {
        short: 'Sales Reporting Frequency',
      },
      'lease.leaseMonths': {
        short: 'Term Length (Months)',
      },
      'lease.hasHvacLandlordWarranty': {
        short: 'Landlord Warranty',
      },
      'lease.hvacLandlordWarrantyTerm': {
        short: 'Warranty Term Length',
      },
      'lease.hvacLandlordWarrantyTerm.unit': {
        short: 'Year(s)',
      },
      'lease.hasThirdPartyContingency': {
        short: 'Contingency for Third Party Consents',
      },
      'lease.hasThirdPartyFranchisee': {
        short: 'Third Party Franchisee',
      },
      'lease.hasLandlordAttorney': {
        short: 'Landlord Attorney',
      },
      'lease.hasLandlordPaysForAudit': {
        short: 'Landlord Pays for Audit',
      },
      'lease.hasLandlordPctRentTerminationRight': {
        short: 'Landlord Termination Right',
      },
      'lease.hasLLRecaptureRight': {
        short: 'Landlord Right to Recapture',
      },
      'lease.landlordPctRentTerminationRightPercentage.unit': {
        short: '% of Breakpoint',
      },
      'lease.landlordPctRentTerminationRightTestingPeriod.unit': {
        short: 'Testing Period',
      },
      'lease.hasReservedParking': {
        short: 'Reserved',
      },
      'lease.reservedParkingPermits': {
        short: 'Number of Permits',
      },
      'lease.restrictedArea.url': {
        short: 'Restricted Area',
      },
      'lease.hasUnreservedParking': {
        short: 'Unreserved',
      },
      'lease.unreservedParkingPermits': {
        short: 'Number of Permits',
      },
      'lease.permitChargesType': {
        short: 'Permit Charges',
      },
      'lease.reimbursementDateType': {
        short: 'Date Tenant Starts Payment',
      },
      'lease.reimbursementDate': {
        short: 'Date',
      },
      'lease.premiseSuites': {
        short: 'Suites',
      },
      'lease.premiseSuites.floor': {
        short: 'Floor',
      },
      'lease.premiseSuites.number': {
        short: 'Number',
      },
      'lease.premiseSuites.areaSize': {
        short: 'Area Size',
      },
      'lease.rofoPremisesType': {
        short: 'ROFO Premises',
      },
      'lease.rofoPremisesFloor': {
        short: 'Floor',
      },
      'lease.rofoPremisesContiguousFloor': {
        short: 'Floor(s)',
      },
      'lease.rofoPremisesNoContiguousFloor': {
        short: 'Floor(s)',
      },
      'lease.rofoTermsType': {
        short: 'Location',
      },
      'lease.rofoPremises.hasArbitration': {
        short: 'Arbitration Method',
      },
      'lease.rofoPremises.arbitrationMarket': {
        short: 'Submarket for Comps',
      },
      'lease.rofoRightType': {
        short: 'ROFO Right Type',
      },
      'lease.rooftop.hasHVAC': {
        short: 'HVAC',
      },
      'lease.rooftop.hasAntennae': {
        short: 'Antennae',
      },
      'lease.rooftop.termsConditionsType': {
        short: 'Terms and Conditions',
      },
      'lease.rooftopEquipment.url': {
        short: 'Upload Rooftop Equipment Exhibit',
        full: 'Upload Rooftop Equipment Exhibit',
      },
      'lease.hasTenantAttorney': {
        short: 'Tenant\'s Attorney',
      },
      'lease.hasTenantRightToReviewBids': {
        short: 'Tenant Right to Review Bids',
      },
      'lease.hasTemporaryPremises': {
        short: 'Add Temporary Premises',
      },
      'lease.temporaryPremises.hasBaseRent': {
        short: 'Tenant Pays Base Rent',
      },
      'lease.temporaryPremises.hasAdditionalRent': {
        short: 'Tenant Pays Additional Rent',
      },
      'lease.temporaryPremises.areaSize': {
        short: 'Temporary Premises Area Size',
      },
      'lease.temporaryPremises.type': {
        short: 'Temporary Premises Type',
      },
      'lease.temporaryPremises.suiteNumber': {
        short: 'Temporary Premises Suite No.',
      },
      'lease.temporaryPremises.spaceOccupied': {
        short: 'Tenant Currently Occupies',
        full: 'Tentant Currently Occupies Temporary Premises',
      },
      'lease.temporaryPremises.baseRent': {
        short: 'Amount',
      },
      'lease.temporaryPremises.floorNumber': {
        short: 'Temporary Premises Floor',
      },
      'lease.holdSpace.optionDate': {
        short: 'Hold Space Option Date',
      },
      'lease.holdSpace.areaSize': {
        short: 'Hold Space Area Size',
      },
      'lease.holdSpace.floor': {
        short: 'Hold Space Floor',
      },
      'lease.premisesImprovementsLandlord': {
        short: 'Premises Improvements Landlord',
      },
      'lease.premisesImprovementsTenant': {
        short: 'Premises Improvements Tenant',
      },
      'lease.tenantInfo': {
        short: 'Tenant Info',
      },
      'lease.guarantyCapBurnOffReductionPeriods.numberOfMonths': {
        short: 'Burndown Months',
        full: 'Number of Months Burndown',
      },
      'lease.hasGrossUp': {
        short: 'Gross-Up Expenses',
        full: 'Gross-Up Expenses',
      },
      'lease.hasGrossSalesReporting': {
        short: 'Gross Sales Reporting',
      },
      'lease.grossSalesReporting.hasMonthlyReporting': {
        short: 'Monthly Sales Reporting',
      },
      'lease.grossSalesReporting.hasQuarterlyReporting': {
        short: 'Quarterly Sales Reporting',
      },
      'lease.grossSalesReporting.hasAnnualReporting': {
        short: 'Annual Sales Reporting',
      },
      'lease.landlordFinancialContribution': {
        short: 'Landlord Financial Contribution',
      },
      'lease.hasFullTimeAccess': {
        short: '24/7 Access',
        full: '24/7 Access',
      },
      'lease.hasLandlordLateDeliveryPenalty': {
        short: 'Late Delivery - Landlord Penalty',
        full: 'Late Delivery - Landlord Penalty',
      },
      'lease.hasLimitTenantFinancials': {
        full: 'Limit Landlord Right to Request Financials',
        short: 'Limit Tenant Financials',
      },
      'lease.hasOpExExclusions': {
        full: 'Add OpEx Exclusions List',
        short: 'OpEx Exclusions',
      },
      'lease.opExExclusions.hasCapExForNewLawsOnly': {
        short: 'CapEx for New Laws Only',
      },
      'lease.opExExclusions.hasManagementFeeExclusion': {
        full: 'Management Fee Exclusion',
        short: 'Management Fee Exclusion',
      },
      'lease.opExExclusions.hasTaxExpenseExclusion': {
        full: 'Tax Expense Exclusion',
        short: 'Tax Expense Exclusion',
      },
      'lease.opExExclusions.hasTenantReceivesBenefitOfGreenBuildingTaxCredit': {
        short: 'Tenant Receives Benefit of Green Building Tax Credit',
      },
      'lease.opExExclusions.hasHighInsuranceDeductibleExclusion': {
        full: 'High Insurance Deductible Exclusion',
        short: 'High Insurance Deductible Exclusion',
      },
      'lease.hasCondenserWater': {
        short: 'Condenser Water',
      },
      'lease.hasContractionOption': {
        short: 'Tenant Contraction Option',
      },
      'lease.changesToPercentageRent': {
        short: 'Percentage Rent Changes',
      },
      'lease.changesToPercentageRent.noChange': {
        short: 'No Change',
      },
      'lease.changesToPercentageRent.noPercentageRent': {
        short: 'No Percentage Rent',
      },
      'lease.changesToPercentageRent.addPercentageRent': {
        short: 'Add Percentage Rent',
      },
      'lease.changesToPercentageRent.resetBreakpoint': {
        short: 'Reset Breakpoint',
      },
      'lease.contractionOption.effectiveDate': {
        short: 'Contraction Effective Date',
      },
      'lease.contractionOption.noticeDate': {
        short: 'Contraction Notice Date',
      },
      'lease.contractionOption.maximumSf': {
        short: 'Maximum % of Rentable Sf',
      },
      'lease.contractionOption.hasContractionFee': {
        short: 'Contraction Fee',
      },
      'lease.contractionOption.fee': {
        short: 'Contraction Fee',
      },
      'lease.hasRightOfFirstRefusal': {
        short: 'Right of First Refusal',
      },
      'lease.rightOfFirstRefusal.area': {
        short: 'ROFR Area Size',
      },
      'lease.rightOfFirstRefusal.suite': {
        short: 'Suite',
      },
      'lease.rightOfFirstRefusal.floor': {
        short: 'Floor',
      },
      'lease.rightOfFirstRefusal.terminationDate': {
        short: 'Option Termination Date',
      },
      'lease.reference': {
        short: 'Reference',
      },
      'lease.refusalAreaPlan.url': {
        short: 'Upload Refusal Space Plan',
      },
      'lease.hasRefusalSpacePlan': {
        short: 'Refusal Space Plan'
      },
      'lease.unreservedParkingPermitsCharge': {
        short: 'Charge Per Permit',
      },
      'lease.reservedParkingPermitsCharge': {
        short: 'Charge Per Permit',
      },
      'lease.expansionNotice.rangePeriodEarliestLength': {
        short: 'Earliest Notice',
      },
      'lease.expansionNotice.rangePeriodLongestLength': {
        short: 'Latest Notice',
      },
      'lease.expansionNotice.leaseYear': {
        short: 'Year Option Exercised',
      },
      'lease.expansionAgreementPeriod': {
        short: 'Acceptance Period',
      },
      'lease.expansionAgreementLeaseYear': {
        short: 'Acceptance Year',
      },
      'lease.expansionRentNoticePeriod': {
        short: 'Rent Acceptance Period',
      },
      'lease.expansionRentNoticeLeaseYear': {
        short: 'Rent Acceptance Year',
      },
      'lease.expansionOption.deliveryDateEarliestLength': {
        short: 'Earliest Delivery',
      },
      'lease.expansionOption.deliveryDateLongestLength': {
        short: 'Latest Delivery',
      },
      'lease.expansionOption.deliveryDateLeaseYear': {
        short: 'Delivery Lease Year',
      },
      'lease.expansionOption.areaMinimum': {
        short: 'Expansion Area Size Minimum',
      },
      'lease.expansionOption.areaMaximum': {
        short: 'Expansion Area Size Maximum',
      },
      'lease.expansionOption.hasExistingOccupant': {
        short: 'Existing Occupant',
      },
      'lease.expansionOption.rentType': {
        short: 'Rent',
      },
      'lease.expansionNoticeWindowStartDate': {
        short: 'Window Start Date',
      },
      'lease.expansionOption.commencement': {
        short: 'Expansion Commencement Date',
      },
      'lease.tenantImprovements.landlordCapAmountUsedBy': {
        short: 'Amount Must Be Used By',
      },
      'lease.tiAllowance.amountUsedBy': {
        short: 'Amount Must Be Used By',
      },
      'lease.rentTableType': {
        short: 'Rent Table Type',
      },
      'lease.rentBumps.begin': {
        short: '',
        full: 'Rent Bump Start Period',
      },
      'lease.rentBumps.duration': {
        short: '',
        full: 'Rent Bump Duration',
      },
      'lease.rentBumps.rentPerSqFoot': {
        short: '',
        full: 'Rent Bump Amount',
      },
      'lease.renewalRentBumps.periods.begin': {
        short: '',
        full: 'Rent Bump Start Period',
      },
      'lease.renewalRentBumps.periods.duration': {
        short: '',
        full: 'Rent Bump Duration',
      },
      'lease.renewalRentBumps.periods.rentPerSqFoot': {
        short: '',
        full: 'Rent Bump Amount',
      },
      'lease.scaledHoldover.firstPeriodDays': {
        short: 'Period',
      },
      'lease.scaledHoldover.firstPeriodPercentage': {
        short: 'Holdover Rent',
      },
      'lease.scaledHoldover.remainingPeriodPercentage': {
        short: 'Holdover Rent',
      },
      'lease.hasApprovedUsers': {
        short: 'Approved Users',
      },
      'lease.hasApprovedSpacePlanComplete': {
        short: 'Approved Space Plan When Complete',
      },
      'lease.tenantSelfInsuranceType': {
        short: 'Self-Insurance Type',
      },
      'lease.hasLandlordDoesConcurrentWork': {
        short: 'Landlord Does Concurrent Work',
      },
      'lease.hasLandlordDoesWorkAfterOccupancy': {
        short: 'Landlord Does Work After Occupancy',
        full: 'Landlord Does Work After Occupancy',
      },
      'lease.hasLandlordDoesAcmWork': {
        short: 'Landlord Does ACM Removal Work',
        full: 'Landlord Does ACM Removal Work',
      },
      'lease.hasLandlordRelocationRight': {
        short: 'Landlord Relocation Right',
        full: 'Landlord Relocation Right',
      },
      'lease.hasInterimLetterOfCredit': {
        short: 'Interim Letter of Credit',
      },
      'lease.hasCapExCompromise': {
        short: 'CapEx Compromises',
      },
      'lease.capExCompromise.conservationProgram': {
        short: 'Conservation Programs',
      },
      'lease.capExCompromise.laws': {
        short: 'Laws at Time of Lease',
      },
      'lease.capExCompromise.amortization': {
        short: 'Amortization',
      },
      'lease.terminationNotice': {
        short: 'Termination Notice',
      },
      'lease.terminationDate': {
        short: 'Termination Date',
        full: 'Termination Date',
      },
      'lease.terminationDateType': {
        short: 'Termination Date Type',
        full: 'Termination Date Type',
      },
      'lease.terminationFeeType': {
        short: 'Termination Fee Type',
        full: 'Termination Fee Type',
      },
      'lease.terminationFeeExhibit.url': {
        short: 'Upload Termination Fee Exhibit',
        full: 'Termination Fee Exhibit',
      },
      'lease.tenantTerminationOptionsSoftFeeInterestRate': {
        short: 'Termination Fee Interest Rate',
        full: 'Termination Fee Interest Rate',
      },
      'lease.hasBalconyRights': {
        short: 'Balcony Rights',
      },
      'lease.hasRooftopRights': {
        short: 'Rooftop Rights',
      },
      'lease.hasGeneratorRights': {
        short: 'Generator Rights',
        full: 'Generator Rights',
      },
      'lease.hasGeneratorCharge': {
        short: 'Additional Charge',
        full: 'Additional Charge',
      },
      'lease.generatorCharge': {
        short: 'Amount',
        full: 'Amount',
      },
      'lease.generatorChargeEscalation': {
        short: 'Escalation',
        full: 'Escalation',
      },
      'lease.hasLandlordConstructsDemisingWalls': {
        short: 'Landlord Constructs Demising Walls',
      },
      'lease.uncontrollableExpenseEstimate': {
        short: 'Estimated Uncontrollable Expenses',
      },
      'lease.hasContingencyConsentToPermittedUse': {
        short: 'Contingency - Consent(s) To Permitted Use',
      },
      'lease.contingencyConsentToPermittedUse.type': {
        short: 'No. Of Consents',
      },
      'lease.contingencyConsentToPermittedUse.period': {
        short: 'No. Of Days To Obtain Consent',
      },
      'lease.terms.estimatedCommencementDateType': {
        short: 'Estimated Term Commencement Date',
      },
      'lease.terms.fixturingPeriod': {
        short: 'Fixturing Period',
      },
      'lease.terms.estimatedCommencementDate': {
        short: 'Date',
      },
      'lease.terms.termCommencementDate': {
        short: 'Term Commencement Date',
      },
      'lease.terms.landlordEstimatedCommencementDate': {
        short: 'Estimated Commencement Date',
      },
      'lease.terms.hasSpecificExpirationDate': {
        short: 'Specific Expiration Date',
      },
      'lease.terms.hasFreeRent': {
        short: 'Free Rent',
      },
      'lease.terms.freeRent': {
        short: 'Period',
        full: 'Free Rent Period',
      },
      'lease.terms.freeRentType': {
        short: 'Free Rent Applies To:',
        full: 'Free Rent Type',
      },
      'lease.terms.hasFreeRentAddedToTerm': {
        short: 'Add Free Rent to Term',
        full: 'Free Rent Added to Term',
      },
      'lease.terms.freeRentExtendedAt': {
        short: 'Extended Lease Year at:',
      },
      'lease.terms.freeRentPeriods': {
        short: 'Free Rent Periods',
      },
      'lease.terms.freeRentPeriods.beginIn': {
        short: 'Begin in (Month)',
      },
      'lease.terms.freeRentPeriods.duration': {
        short: 'Duration (Months)',
      },
      'lease.terms.freeRentPeriods.percentFree': {
        short: '% Free',
      },
      'lease.terms.freeRentPeriods.amount': {
        short: 'Abated Base Rent',
      },
      'lease.terms.spaces': {
        short: 'Spaces (Select all that apply)',
      },
      'lease.terms.hasMustTakeSpace': {
        short: 'Must Take Space',
      },
      'lease.hasLandlordAreaRemeasurements': {
        short: 'Landlord Remeasured Premises/Building',
      },
      'lease.landlordAreaRemeasurements.hasPremisesAreaRemeasurements': {
        short: 'Premises Area Has Updated',
      },
      'lease.landlordAreaRemeasurements.formerPremisesArea': {
        short: 'Former Premises Area',
      },
      'lease.landlordAreaRemeasurements.hasBuildingAreaRemeasurements': {
        short: 'Building Area Has Updated',
      },
      'lease.landlordAreaRemeasurements.formerBuildingArea': {
        short: 'Former Building Area',
      },
      'lease.hasChangeBaseYear': {
        short: 'Change Base Year',
      },
      'lease.hasChangeModifiedGrossTerms': {
        short: 'Change Modified Gross Terms',
      },
      'lease.hasChangesAdditionalRentType': {
        short: 'Changes to Additional Rent Type',
      },
      'lease.insurance.landlordTerminationForCasualty': {
        short: 'LL Right to Terminate for Casualty'
      },
      'lease.insurance.landlordTerminationForCondemnation': {
        short: 'LL Right to Terminate for Condemnation'
      },
      'lease.hasLandlordTerminationRight': {
        short: 'Landlord Termination Right',
      },
      'lease.hasAddInsuranceToBaseYear': {
        short: 'Add Insurance to Base Year'
      },
      'lease.opExExclusions.hasEquipmentExcludedAsCapEx': {
        short: 'Equipment Excluded as CapEx'
      },
      'lease.hasMakeTenantRepsMutual': {
        short: 'Make Tenant Reps Mutual'
      },
      'lease.hasLimitOnThreeStrikesDefault': {
        short: 'Limit on 3 Strikes Default'
      },
      'lease.hasLimitationOnHoldoverDamages': {
        short: 'Limitation on Consequential Damages for Holdover'
      },
      'lease.hasLimitationOnRightToAlterParking': {
        short: 'Limitation on Rights to Alter Parking'
      },
      'lease.hasNoticePriorToEntry': {
        short: 'Notice Prior to Landlord Entry'
      },
      'lease.assignmentInfo.hasConfidentialityRestrictionsForNotice': {
        short: 'Confidentiality Restrictions for Notice'
      },
      'lease.assignmentInfo.hasDeductExpenseFromTransferPremium': {
        short: 'Deduct Expenses from Transfer Premium'
      },
      'lease.landlordComplianceWithLaws': {
        short: 'Common Area Compliance with Laws'
      },
      'lease.landlordCoordinationFeePercentageAmount': {
        short: 'Amount'
      },
      'lease.hasRentAbatementForClosure': {
        short: 'Rent Abatement for Closure',
      },
      'lease.rentAbatementForClosure': {
        short: 'Level of Landlord Control of Closure',
      },
      'lease.hasAmendedProvisions': {
        short: 'Amended Provisions',
      },
      'lease.hasAssignmentFee': {
        short: 'Assignment Review Fee',
      },
      'lease.hasAssignmentToAffiliate': {
        short: 'Assignment to Affiliate',
      },
      'lease.hasWindowSignage': {
        short: 'Window Signage',
      },
      'lease.assignmentFee': {
        short: 'Amount',
      },
      'lease.landlordDelayAbatementTrigger': {
        short: 'Abatement Trigger',
      },
      'lease.landlordDelayOutsideDate': {
        short: 'Outside Date',
      },
      'lease.landlordDelayPeriodAfterCompletionDate': {
        short: 'Period After Completion Date',
      },
      'lease.landlordLateDeliveryPenalty.abatementPeriodAfterDelivery': {
        short: 'Period After Completion Date',
      },
      'lease.landlordLateDeliveryPenalty.tenantTerminationRightPeriodAfterDelivery': {
        short: 'Period After Completion Date',
      },
      'lease.landlordDelayAmount': {
        short: 'Amount',
      },
      'lease.hasRentDeferral': {
        short: 'Rent Deferral',
      },
      'lease.rentDeferral.deferralStartDate': {
        short: 'Rent Deferral Start Date',
      },
      'lease.rentDeferral.deferralEndDate': {
        short: 'Rent Deferral End Date',
      },
      'lease.rentDeferral.rentReducedBy': {
        short: 'Rent Reduced By',
      },
      'lease.rentDeferral.deferredRentDollarAmount': {
        short: 'Deferred Rent Amount',
      },
      'lease.rentDeferral.rentDeferralAppliesTo': {
        short: 'Rent Deferral Applies To',
      },
      'lease.rentDeferral.rentReductionType': {
        short: 'Reduced Rent Amount Type',
      },
      'lease.rentDeferral.reducedRentDollarAmount': {
        short: 'Reduced Rent Amount',
      },
      'lease.rentDeferral.reducedRentPercentOfSalesAmount': {
        short: 'Reduced Rent Amount (% of Gross Sales)',
      },
      'lease.rentDeferral.repaymentStartDate': {
        short: 'Repayment Start Date',
      },
      'lease.rentDeferral.repaymentPeriods': {
        short: 'Repayment Periods',
      },
      'lease.hiddenByRentDeferralMessage': {
        short: 'Not Applicable In A Rent Deferral',
      },
      'lease.hasRentReduction': {
        short: 'Rent Reduction',
      },
      'lease.rentReduction.startDate': {
        short: 'Rent Reduction Start Date',
      },
      'lease.rentReduction.endDate': {
        short: 'Rent Reduction End Date',
      },
      'lease.rentReduction.rentReducedBy': {
        short: 'Rent Reduced By',
      },
      'lease.rentReduction.reducedRentDollarAmount': {
        short: 'Reduced Rent Amount',
      },
      'lease.rentReduction.reducedRentPercentOfSalesAmount': {
        short: 'Reduced Rent Amount (% of Gross Sales)',
      },
      'lease.rentReduction.rentReductionAppliesTo': {
        short: 'Rent Reduction Applies To',
      },
      'lease.rentReduction.rentReductionType': {
        short: 'Reduced Rent Amount Type',
      },
      'lease.fixedCommencementDateType': {
        short: 'Fixed Commencement Date Type',
      },
      'lease.commencementDateType': {
        short: 'Commencement Date Type',
      },
      'lease.hasEstimatedCommencementDate': {
        short: 'Estimated Commencement Date',
      },
      'lease.hasEstimatedDeliveryDate': {
        short: 'Estimated Delivery Date',
      },
      // Radio buttons
      'lease.tenantInfo.type.entity': {
        short: 'Entity',
      },
      'lease.tenantInfo.type.individual': {
        short: 'Individual',
      },
      'lease.taxBaseYearType.calendarYear': {
        short: 'Calendar Year',
      },
      'lease.taxBaseYearType.fiscalYear': {
        short: 'Fiscal Year',
      },
      'lease.terms.freeRentType.baseRentOnly': {
        short: 'Base Rent Only',
      },
      'lease.terms.freeRentType.baseRentAndAdditionalRent': {
        short: 'Base Rent And Additional Rent',
      },
      'lease.terms.freeRentExtendedAt.beginning': {
        short: 'Beginning of Term',
      },
      'lease.terms.freeRentExtendedAt.end': {
        short: 'End of Term',
      },
      'lease.terms.renewalOptionRent.fixed': {
        short: 'Fixed Rent',
      },
      'lease.terms.renewalOptionRent.fmv': {
        short: 'FMV Rent',
      },
      'lease.rentAbatementForClosure.withinLLControlOnly': {
        short: 'Closure Within LL\'s Control Only',
      },
      'lease.rentAbatementForClosure.withinAndOutsideLLControl': {
        short: 'Closure Within or Outside LL\'s Control',
      },
      'lease.assigneeInfo.type.entity': {
        short: 'Entity',
      },
      'lease.assigneeInfo.type.individual': {
        short: 'Individual',
      },
      'lease.priorAssignments.type.tenant': {
        short: 'Tenant',
      },
      'lease.priorAssignments.type.landlord': {
        short: 'Landlord',
      },
      'lease.burnOff.anyDefault': {
        short: 'Any Default',
      },
      'lease.burnOff.monetaryDefault': {
        short: 'Monetary Default',
      },
      'lease.letterOfCredit.optional': {
        short: 'Optional',
      },
      'lease.letterOfCredit.required': {
        short: 'Required',
      },
      'lease.sublet.soleDiscretion': {
        short: 'Sole Discretion',
      },
      'lease.sublet.reasonable': {
        short: 'Reasonable',
      },
      'lease.assignment.soleDiscretion': {
        short: 'Sole Discretion',
      },
      'lease.assignment.reasonable': {
        short: 'Reasonable',
      },
      'lease.tenantImprovements.payment.turnkey': {
        short: 'Turnkey Construction',
      },
      'lease.tenantImprovements.payment.landlordCap': {
        short: 'Tenant Improvement Allowance',
      },
      'lease.commonAreaWorkPayment.landlord': {
        short: 'Paid For By Landlord',
      },
      'lease.commonAreaWorkPayment.allTenants': {
        short: 'Allocated Among All Tenants on Floor',
      },
      'lease.whoCompletesPlans.landlord': {
        short: 'Landlord',
      },
      'lease.whoCompletesPlans.tenant': {
        short: 'Tenant',
      },
      'lease.whenPlansCompleted.beforeLease': {
        short: 'Prior to Lease Execution',
      },
      'lease.whenPlansCompleted.afterLease': {
        short: 'After Lease Execution',
      },
      'lease.allowanceScheduleType.bifurcated': {
        short: 'Bifurcated',
      },
      'lease.allowanceScheduleType.nonBifurcated': {
        short: 'Non-Bifurcated',
      },
      'lease.landlordWorkDeliveryCondition.rawShell': {
        short: 'Raw Shell',
      },
      'lease.landlordWorkDeliveryCondition.vanillaShell': {
        short: 'Vanilla Shell',
      },
      'lease.outdoorSeatingType.toBeApproved': {
        short: 'To be Approved',
      },
      'lease.outdoorSeatingType.shownOnPlan': {
        short: 'Shown on Plan',
      },
      'lease.landlordPermittedUseRep.noViolation': {
        short: 'No Violation of Exclusives',
      },
      'lease.landlordPermittedUseRep.exhibit': {
        short: 'All Exclusives Listed on Exhibit',
      },
      'lease.auditRightType.largeTenant': {
        short: 'Large Tenant',
      },
      'lease.auditRightType.midSizedTenant': {
        short: 'Mid-sized Tenant',
      },
      'lease.pctRentHasNaturalBreakpoint.natural': {
        short: 'Natural',
      },
      'lease.pctRentHasNaturalBreakpoint.unnatural': {
        short: 'Unnatural',
      },
      'lease.fixedCamType.leaseYear': {
        short: 'Lease Year',
      },
      'lease.fixedCamType.calendarYear': {
        short: 'Calendar Year',
      },
      'lease.camCap.capOnCamType.cumulative': {
        short: 'Cumulative',
      },
      'lease.camCap.capOnCamType.nonCumulative': {
        short: 'Non Cumulative',
      },
      'lease.permitChargesType.included': {
        short: 'Included in Rent',
      },
      'lease.permitChargesType.notIncluded': {
        short: 'Not Included in Rent',
      },
      'lease.rofoPremisesType.contiguous': {
        short: 'Contiguous Premises',
      },
      'lease.rofoPremisesType.notContiguous': {
        short: 'Not Contiguous to Premises',
      },
      'lease.rofoRightType.oneTime': {
        short: 'One-Time Right',
      },
      'lease.rofoRightType.ongoing': {
        short: 'Ongoing Right',
      },
      'lease.goDarkOption.openingObligation': {
        short: 'One Day',
      },
      'lease.goDarkOption.operatingPeriod': {
        short: 'Specified Period of Time:',
      },
      'lease.requiredOpening.from.delivery': {
        short: 'Delivery Date',
      },
      'lease.requiredOpening.from.commencement': {
        short: 'Commencement Date',
      },
      'lease.permitContingencyType.landlordTermination': {
        short: 'Landlord Right to Terminate',
      },
      'lease.permitContingencyType.mutualTermination': {
        short: 'Mutual Right to Terminate',
      },
      'lease.pctRentHasNaturalBreakpoint.natural': {
        short: 'Natural Breakpoint',
      },
      'lease.pctRentHasNaturalBreakpoint.unnatural': {
        short: 'Unnatural Breakpoint',
      },
      'lease.hasExclusiveUse.scope.whole': {
        short: 'Entire Shopping Center',
      },
      'lease.hasExclusiveUse.scope.partial': {
        short: 'Portion of Shopping Center',
      },
      'lease.rooftop.termsConditionsType.inLease': {
        short: 'In Lease',
      },
      'lease.rooftop.termsConditionsType.licenseAgreement': {
        short: 'License Agreement',
      },
      'lease.freeRentExtendedAt.beginning': {
        short: 'Beginning of Term',
      },
      'lease.freeRentExtendedAt.end': {
        short: 'End of Term',
      },
      'lease.freeRentType.baseRentOnly': {
        short: 'Base Rent Only',
      },
      'lease.freeRentType.baseRentAndAdditionalRent': {
        short: 'Base Rent And Additional Rent',
      },
      'lease.renewalOptionRent.fixed': {
        short: 'Fixed Rent',
      },
      'lease.renewalOptionRent.fmv': {
        short: 'FMV Rent',
      },
      'lease.earlyAccessType.installProperty': {
        short: 'Install Property',
      },
      'lease.earlyAccessType.installCabling': {
        short: 'Install Cabling',
      },
      'lease.tenantInfo.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.tenantInfo.entityType.limitedLiabilityCompany': {
        short: 'Limited Liability Company',
      },
      'lease.tenantInfo.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.tenantInfo.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.tenantInfo.entityType.professionalCorporation': {
        short: 'Professional Corporation',
      },
      'lease.tenantInfo.entityType.governmentEntity': {
        short: 'Government Entity',
      },
      'lease.tenantInfo.entityType.nonProfitCorporation': {
        short: 'Non-Profit Corporation',
      },
      'lease.tenantInfo.entityType.other': {
        short: 'Other',
      },
      'lease.additionalRentType.noChange': {
        short: 'No Change',
      },
      'lease.additionalRentType.modifiedGross': {
        short: 'Modified Gross',
      },
      'lease.additionalRentType.gross': {
        short: 'Gross',
      },
      'lease.additionalRentType.tripleNet': {
        short: 'Triple Net',
      },
      'lease.additionalRentType.tripleNetPure': {
        short: 'Triple Net (Pure)',
      },
      'lease.additionalRentType.tripleNetPartial': {
        short: 'Triple Net (Partial)',
      },
      'lease.additionalRentType.tripleNetLandlordControl': {
        short: 'Triple Net (Landlord Control)',
      },
      'lease.fixedCommencementDateType.date': {
        short: 'Date'
      },
      'lease.fixedCommencementDateType.executionOfLease': {
        short: 'Execution of Lease'
      },
      'lease.commencementDateType.fixed': {
        short: 'Fixed'
      },
      'lease.commencementDateType.floating': {
        short: 'Floating'
      },
      'lease.landlordComplianceWithLaws.none': {
        short: 'Not Required to Comply',
      },
      'lease.landlordComplianceWithLaws.ada': {
        short: 'Compliance With ADA Only',
      },
      'lease.landlordComplianceWithLaws.allLaws': {
        short: 'Compliance With All Laws Including ADA',
      },
      'lease.landlordComplianceWithLaws.allLawsExceptAda': {
        short: 'Compliance With All Laws Not Including ADA',
      },
      'lease.contingencyConsentToPermittedUse.type.one': {
        short: 'One',
      },
      'lease.contingencyConsentToPermittedUse.type.moreThanOne': {
        short: 'More than 1',
      },
      'lease.cb.deadlineForExpenseStatement.noDeadline': {
        short: 'No Deadline',
      },
      'lease.cb.deadlineForExpenseStatement.reasonableEfforts': {
        short: 'Reasonable Efforts by June 1',
      },
      'lease.cb.deadlineForExpenseStatement.fixedDateWithPenalties': {
        short: 'Fixed Date with Penalties',
      },
      'lease.landlordDelayAbatementTrigger.fixedDateMissed': {
        short: 'Fixed Date Missed',
      },
      'lease.landlordDelayAbatementTrigger.softDateMissed': {
        short: 'Soft Date Missed',
      },
      'lease.insurance.landlordTerminationForCasualty.noRestriction': {
        short: 'No Restriction',
      },
      'lease.insurance.landlordTerminationForCasualty.noDiscrimination': {
        short: 'No Discrimination Against Tenant',
      },
      'lease.insurance.landlordTerminationForCasualty.terminateAll': {
        short: 'Termination of All Other Leases',
      },
      'lease.insurance.landlordTerminationForCondemnation.noRestriction': {
        short: 'No Restriction',
      },
      'lease.insurance.landlordTerminationForCondemnation.noDiscrimination': {
        short: 'No Discrimination Against Tenant',
      },
      'lease.insurance.landlordTerminationForCondemnation.terminateAll': {
        short: 'Termination of All Other Leases',
      },
      'lease.rentInputType.manual': {
        short: 'Per Square Foot',
      },
      'lease.rentInputType.automatic': {
        short: 'Per Square Foot with Annual Escalation',
      },
      'lease.rentInputType.minimumIncreaseCPI': {
        short: 'Per Square Foot with CPI Increase Minimum',
      },
      'lease.rentInputType.fixed': {
        short: 'Fixed - Monthly',
      },
      'lease.rentInputType.fixedNoEscalation': {
        short: 'Fixed - Monthly No Escalation',
      },
      'lease.rentInputType.flexible': {
        short: 'Flexible',
      },
      'lease.rentInputType.flexibleAmendment': {
        short: 'Flexible Amendment',
      },
      'lease.terms.estimatedCommencementDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.terms.estimatedCommencementDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.renewalCount.one': {
        short: 'One',
      },
      'lease.renewalCount.two': {
        short: 'Two',
      },
      'lease.renewalCount.three': {
        short: 'Three',
      },
      'lease.renewalCount.four': {
        short: 'Four',
      },
      'lease.renewalTermNoticeType.statedPeriod': {
        short: 'Stated Period',
      },
      'lease.renewalTermNoticeType.rangePeriod': {
        short: 'Range',
      },
      'lease.renewalOptionRent.fmv': {
        short: 'Rent Set At Fair Market Value',
      },
      'lease.renewalOptionRent.fixed': {
        short: 'Rent Specified in Lease',
      },
      'lease.renewalOptionRent.percentFmv': {
        short: 'Rent Set At % Of Fair Market Value',
      },
      'lease.renewalInfo.renewalInputType.manual': {
        short: 'Per Square Foot',
      },
      'lease.renewalInfo.renewalInputType.automatic': {
        short: 'Per Square Foot with Annual Escalation',
      },
      'lease.renewalInfo.renewalInputType.minimumIncreaseCPI': {
        short: 'Per Square Foot with CPI Increase Minimum',
      },
      'lease.renewalInfo.renewalInputType.fixed': {
        short: 'Fixed - Monthly',
      },
      'lease.renewalInfo.renewalInputType.fixedNoEscalation': {
        short: 'Fixed - Monthly No Escalation',
      },
      'lease.renewalInfo.renewalInputType.flexible': {
        short: 'Flexible',
      },
      'lease.expansion.extensionTermType.softDate': {
        short: 'Keyed off Expansion Effective Date',
      },
      'lease.expansion.extensionTermType.hardDate': {
        short: 'Hard Expiration Date',
      },
      'lease.relocation.extensionTermType.softDate': {
        short: 'Keyed off Relocation Effective Date',
      },
      'lease.relocation.extensionTermType.hardDate': {
        short: 'Hard Expiration Date',
      },
      'lease.reduction.extensionTermType.softDate': {
        short: 'Keyed off Reduction Effective Date',
      },
      'lease.reduction.extensionTermType.hardDate': {
        short: 'Hard Expiration Date',
      },
      'lease.rentDeferral.rentDeferralAppliesTo.baseRent': {
        short: 'Base Rent',
      },
      'lease.rentDeferral.rentDeferralAppliesTo.baseRentAndAdditionalRent': {
        short: 'Base Rent and Additional Rent',
      },
      'lease.rentDeferral.rentReductionType.dollarAmount': {
        short: '$ Amount',
      },
      'lease.rentDeferral.rentReductionType.percentageOfGrossSales': {
        short: '% of Gross Sales',
      },
      'lease.rentDeferral.rentReductionAppliesTo.baseRent': {
        short: 'Base Rent',
      },
      'lease.rentDeferral.rentReductionAppliesTo.baseRentAndAdditionalRent': {
        short: 'Base Rent and Additional Rent',
      },
      'lease.rentReduction.rentReductionType.ddollarAmount': {
        short: '$ Amount',
      },
      'lease.rentReduction.rentReductionType.percentageOfGrossSales': {
        short: '% of Gross Sales',
      },
      'lease.cb.shoppingCenterViolationContingencyTenantUnableToObtain.certificateOfOccupancy': {
        short: 'Certificate of Occupancy',
      },
      'lease.cb.shoppingCenterViolationContingencyTenantUnableToObtain.buildingPermit': {
        short: 'Building Permit',
      },
      'lease.temporaryPremises.type.temporaryPremises': {
        short: 'Temporary Premises',
      },
      'lease.temporaryPremises.type.holdSpace': {
        short: 'Hold Space Option',
      },
      'lease.temporaryPremises.commencementDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.temporaryPremises.commencementDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.assigneeInfo.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.assigneeInfo.entityType.limitedLiabilityCompany': {
        short: 'Limited Liability Company',
      },
      'lease.assigneeInfo.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.assigneeInfo.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.assigneeInfo.entityType.other': {
        short: 'Other',
      },
      'lease.tenantCompetitors.requiredOccupancy.sf': {
        short: 'Min. Area - SF',
      },
      'lease.tenantCompetitors.requiredOccupancy.percentage': {
        short: 'Min. Area - %',
      },
      'lease.securityDepositType.letterOfCredit': {
        short: 'Letter of Credit (L/C)',
      },
      'lease.securityDepositType.cash': {
        short: 'Cash',
      },
      'lease.securityDepositType.cashAndLetterOfCredit': {
        short: 'Cash and Letter of Credit',
      },
      'lease.existingGuarantorInfo.entities.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.existingGuarantorInfo.entities.entityType.limitedLiabilityCompany': {
        short: 'Limited Liability Company',
      },
      'lease.existingGuarantorInfo.entities.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.existingGuarantorInfo.entities.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.existingGuarantorInfo.entities.entityType.other': {
        short: 'Other',
      },
      'lease.monetaryCapType.amount': {
        short: 'Amount',
      },
      'lease.monetaryCapType.months': {
        short: 'Number of Months of Rent',
      },
      'lease.guarantyCapBurndownType.months': {
        short: 'Number of Months',
      },
      'lease.guarantyCapBurndownType.amount': {
        short: 'Amount',
      },
      'lease.guarantorInfo.entities.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.guarantorInfo.entities.entityType.limitedLiabilityCompany': {
        short: 'Limited liability company',
      },
      'lease.guarantorInfo.entities.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.guarantorInfo.entities.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.guarantorInfo.entities.entityType.other': {
        short: 'Other',
      },
      'lease.umbrellaLimit.10000000': {
        short: '$10,000,000',
      },
      'lease.umbrellaLimit.5000000': {
        short: '$5,000,000',
      },
      'lease.expansion.commencementDateType.fixed': {
        short: 'Fixed',
      },
      'lease.expansion.commencementDateType.floating': {
        short: 'Floating',
      },
      'lease.tenantSelfInsuranceType.parent': {
        short: 'Parent',
      },
      'lease.tenantSelfInsuranceType.tenantEntity': {
        short: 'Tenant Entity',
      },
      'lease.tenantLiabilityInsuranceAmount.1000000': {
        short: '$1,000,000',
      },
      'lease.tenantLiabilityInsuranceAmount.2000000': {
        short: '$2,000,000',
      },
      'lease.tenantLiabilityInsuranceAmount.3000000': {
        short: '$3,000,000',
      },
      'lease.tenantLiabilityInsuranceAmount.4000000': {
        short: '$4,000,000',
      },
      'lease.tenantLiabilityInsuranceAmount.5000000': {
        short: '$5,000,000',
      },
      'lease.tenantImprovements.type.landlord': {
        short: 'Landlord Constructs Tenant Improvements',
      },
      'lease.tenantImprovements.type.tenant': {
        short: 'Tenant Constructs Tenant Improvements',
      },
      'lease.tenantImprovements.type.none': {
        short: '"As Is" - No Tenant Improvements',
      },
      'lease.estimatedDeliveryDate.hardDate': {
        short: 'Hard Delivery Date',
      },
      'lease.estimatedDeliveryDate.softDate': {
        short: 'Soft Delivery Date',
      },
      'lease.designationOfImprovementsAsBuildingStandard.noDesignation': {
        short: 'No Designation',
      },
      'lease.designationOfImprovementsAsBuildingStandard.uponTenantRequest': {
        short: 'Upon Tenant Request',
      },
      'lease.designationOfImprovementsAsBuildingStandard.requiredWhenConsentGiven': {
        short: 'Required When Consent Given',
      },
      'lease.tiAllowance.method.total': {
        short: 'Total Amount',
      },
      'lease.tiAllowance.method.psf': {
        short: 'Amount PSF',
      },
      'lease.tiAllowance.drawType.1': {
        short: '1',
      },
      'lease.tiAllowance.drawType.2': {
        short: '2',
      },
      'lease.tiAllowance.drawType.3': {
        short: '3',
      },
      'lease.tiAllowance.drawType.multiple': {
        short: 'Multiple',
      },
      'lease.relocation.commencementDateType.fixed': {
        short: 'Fixed',
      },
      'lease.relocation.commencementDateType.floating': {
        short: 'Floating',
      },
      'lease.premisesModType.none': {
        short: 'None',
      },
      'lease.premisesModType.expansion': {
        short: 'Expansion',
      },
      'lease.premisesModType.relocation': {
        short: 'Relocation',
      },
      'lease.premisesModType.reduction': {
        short: 'Reduction',
      },
      'lease.premisesModType.termination': {
        short: 'Termination',
      },
      'lease.landlordDelayAbatementTrigger.fixedDateMissed': {
        short: 'Fixed Date Missed',
      },
      'lease.landlordDelayAbatementTrigger.softDateMissed': {
        short: 'Soft Date Missed',
      },
      'lease.tiAllowance.dropDeadDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.tiAllowance.dropDeadDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.landlordRelocationRightType.option1': {
        short: 'Basic',
      },
      'lease.landlordRelocationRightType.option2': {
        short: 'Tenant Termination',
      },
      'lease.landlordRelocationRightType.option3': {
        short: 'Specific Area',
      },
      'lease.landlordRelocationRightType.option4': {
        short: 'LL Termination - Tenant Rejects',
      },
      'lease.landlordRelocationRightType.option5': {
        short: 'LL Termination - No Comp. Premises',
      },
      'lease.contingencyTermination.monthToMonth': {
        short: 'Month-to-Month',
      },
      'lease.contingencyTermination.earlyTermination': {
        short: 'Early Termination',
      },
      'lease.contingencyTermination.naturalExpiration': {
        short: 'Natural Expiration',
      },
      'lease.contingencyTermination.tenantConsent': {
        short: 'Existing Tenant Consent',
      },
      'lease.utilitiesCharge.separatelyMetered': {
        short: 'Separately Metered',
      },
      'lease.utilitiesCharge.flatCharge': {
        short: 'Flat Charge',
      },
      'lease.utilitiesCharge.landlordObligation': {
        short: 'Landlord Obligation',
      },
      'lease.utilitiesCharge.landlordMaintainAndDeductFromTenantDeposit': {
        short: 'Landlord Maintain and Deduct from Tenant Deposit',
      },
      'lease.utilitiesCharge.tenantObligation': {
        short: 'Tenant Obligation',
      },
      'lease.utilitiesCharge.none': {
        short: 'None',
      },
      'lease.reimbursementDateType.commencementDate': {
        short: 'Commencement Date',
      },
      'lease.reimbursementDateType.firstAnniversary': {
        short: 'First Anniversary',
      },
      'lease.reimbursementDateType.stipulatedDate': {
        short: 'Stipulated Date',
      },
      'lease.salesReportingFreq.annually': {
        short: 'Annually',
      },
      'lease.salesReportingFreq.quarterly': {
        short: 'Quarterly',
      },
      'lease.salesReportingFreq.monthly': {
        short: 'Monthly',
      },
      'lease.salesReportingFreq.semiAnnually': {
        short: 'Semi-Annually',
      },
      'lease.rentTableType.separate': {
        short: 'Separate',
      },
      'lease.rentTableType.blended': {
        short: 'Blended',
      },
      'lease.extendingTermBumpsKeyedOf.extension': {
        short: 'Extension',
      },
      'lease.extendingTermBumpsKeyedOf.expansion': {
        short: 'Expansion',
      },
      'lease.expansionOption.rentType.existing': {
        short: 'Same as Existing',
      },
      'lease.expansionOption.rentType.market': {
        short: 'Prevailing Market',
      },
      'lease.expansionOption.deliveryDate.specificDate': {
        short: 'Specific Date',
      },
      'lease.expansionOption.deliveryDate.window': {
        short: 'Delivery Window',
      },
      'lease.expansionOption.deliveryDate.range': {
        short: 'Range',
      },
      'lease.rofoTermsType.landlordOffer': {
        short: 'As Stated in Landlord\'s Offer',
      },
      'lease.rofoTermsType.fairMarket': {
        short: 'Fair Market',
      },
      'lease.terminationFeeType.hardAmount': {
        short: 'Hard Amount',
      },
      'lease.terminationFeeType.softAmount': {
        short: 'Soft Amount',
      },
      'lease.tenantTerminationType.oneTime': {
        short: 'One-time Right',
      },
      'lease.tenantTerminationType.continuing': {
        short: 'Continuing Right',
      },
      'lease.terminationDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.terminationDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.openingCoTenancy.alternativeRent.grossSales': {
        short: '% of Gross Sales',
      },
      'lease.openingCoTenancy.alternativeRent.baseRent': {
        short: '% of Base Rent',
      },
      'lease.estimatedCommencementDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.estimatedCommencementDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.fixturingPeriodType.earlierOfDays': {
        short: 'Earlier of days/opening',
      },
      'lease.fixturingPeriodType.specificDays': {
        short: 'Specific # of days',
      },
      'lease.deliveryDateType.hardDate': {
        short: 'Hard Date',
      },
      'lease.deliveryDateType.softDate': {
        short: 'Soft Date',
      },
      'lease.deliveryDateType.deliveredAtLeaseExecution': {
        short: 'Deemed Delivered At Lease Execution',
      },
      'lease.earlyAccessDateType.specifiedDate': {
        short: 'Specified Date',
      },
      'lease.earlyAccessDateType.constructionSchedule': {
        short: 'Construction Schedule',
      },
      'lease.franchisorInfo.entityType.corporation': {
        short: 'Corporation',
      },
      'lease.franchisorInfo.entityType.limitedLiabilityCompany': {
        short: 'Limited Liability Company',
      },
      'lease.franchisorInfo.entityType.generalPartnership': {
        short: 'General Partnership',
      },
      'lease.franchisorInfo.entityType.limitedPartnership': {
        short: 'Limited Partnership',
      },
      'lease.franchisorInfo.entityType.other': {
        short: 'Other',
      },
      'lease.event.name': {
        short: "Event Name",
      },
      'lease.event.times.length.title': {
        short: "Event Date & Time",
      },
      'lease.event.times.date': {
        short: "Date",
      },
      'lease.event.times.startDate': {
        short: "Start Date",
      },
      'lease.event.times.endDate': {
        short: "End Date",
      },
      'lease.event.times.startTime.hour': {
        short: "Hour",
      },
      'lease.event.times.startTime.minute': {
        short: "Minutes",
      },
      'lease.event.times.startTime.meridian': {
        short: "am/pm",
      },
      'lease.event.times.endTime.hour': {
        short: "Hour",
      },
      'lease.event.times.endTime.minute': {
        short: "Minutes",
      },
      'lease.event.times.endTime.meridian': {
        short: "am/pm",
      },
      'lease.event.commencement.date': {
        short: "Commencement Date",
      },
      'lease.event.commencement.time.hour': {
        short: "Hour",
      },
      'lease.event.commencement.time.minute': {
        short: "Minutes",
      },
      'lease.event.commencement.time.meridian': {
        short: "am/pm",
      },
      'lease.event.expiration.date': {
        short: "Expiration Date",
      },
      'lease.event.expiration.time.hour': {
        short: "Hour",
      },
      'lease.event.expiration.time.minute': {
        short: "Minutes",
      },
      'lease.event.expiration.time.meridian': {
        short: "am/pm",
      },
      'lease.event.setupTime': {
        short: "Setup Access",
      },
      'lease.event.setupTime.hour': {
        short: "Hour",
      },
      'lease.event.setupTime.minute': {
        short: "Minutes",
      },
      'lease.event.cleanupTime': {
        short: "Cleanup Access",
      },
      'lease.event.cleanupTime.hour': {
        short: "Hour",
      },
      'lease.event.cleanupTime.minute': {
        short: "Minutes",
      },
      'lease.event.startLoadInTime.hour': {
        short: "Hour",
      },
      'lease.event.startLoadInTime.minute': {
        short: "Minutes",
      },
      'lease.event.startLoadInTime.meridian': {
        short: "am/pm",
      },
      'lease.event.endLoadInTime.hour': {
        short: "Hour",
      },
      'lease.event.endLoadInTime.minute': {
        short: "Minutes",
      },
      'lease.event.endLoadInTime.meridian': {
        short: "am/pm",
      },
      'lease.event.startLoadOutTime.hour': {
        short: "Hour",
      },
      'lease.event.startLoadOutTime.minute': {
        short: "Minutes",
      },
      'lease.event.startLoadOutTime.meridian': {
        short: "am/pm",
      },
      'lease.event.endLoadOutTime.hour': {
        short: "Hour",
      },
      'lease.event.endLoadOutTime.minute': {
        short: "Minutes",
      },
      'lease.event.endLoadOutTime.meridian': {
        short: "am/pm",
      },
      'lease.event.attendeesNumber': {
        short: "Number of Attendees",
      },
      'lease.event.contact.name': {
        short: "Name",
      },
      'lease.event.contact.position': {
        short: "Position",
      },
      'lease.event.contact.address': {
        short: "Address",
      },
      'lease.event.contact.city': {
        short: "City",
      },
      'lease.event.contact.state': {
        short: "State",
      },
      'lease.event.contact.zip': {
        short: "Zip",
      },
      'lease.event.contact.phone': {
        short: "Phone",
      },
      'lease.event.contact.email': {
        short: "Email",
      },
      'lease.event.landlordContact.name': {
        short: "Name",
      },
      'lease.event.landlordContact.email': {
        short: "Email",
      },
      'lease.event.expiration.title': {
        short: "Expiration Date",
      },
      'lease.event.commencement.title': {
        short: "Commencement Date",
      },
      'lease.event.description': {
        short: "Description of the Event",
      },
      'lease.event.hasFee': {
        short: "Fee",
      },
      'lease.event.feeType': {
        short: "Fee Type",
      },
      'lease.event.feePayer.licensee': {
        short: "Licensee",
      },
      'lease.event.feePayer.licensor': {
        short: "Licensor",
      },
      'lease.event.feeType.flatFee': {
        short: "Flat Fee"
      },
      'lease.event.hasBaseFee': {
        short: "Add Base Fee",
      },
      'lease.event.feeType.percentOfGrossSales': {
        short: "% of Gross Sales"
      },
      'lease.event.feeType.percentOfTicketSales': {
        short: "% of Ticket Sales"
      },
      'lease.event.fee': {
        short: "Fee Amount",
      },
      'lease.event.baseFee': {
        short: "Base Fee",
      },
      'lease.event.hasRecurring': {
        short: "Recurring Event",
      },
      'lease.event.times.startTime.title': {
        short: "Start Time",
      },
      'lease.event.times.endTime.title': {
        short: "End Time",
      },
      'lease.event.setupTime.title': {
        short: "Set-Up Length",
      },
      'lease.event.loadInAccess.title': {
        short: "Load-In Access",
      },
      'lease.event.startLoadInTime.title': {
        short: "Start Time",
      },
      'lease.event.endLoadInTime.title': {
        short: "End Time",
      },
      'lease.event.loadOutAccess.title': {
        short: "Load-Out Access",
      },
      'lease.event.startLoadOutTime.title': {
        short: "Start Time",
      },
      'lease.event.endLoadOutTime.title': {
        short: "End Time",
      },
      'lease.event.contact.title': {
        short: "Contact Information",
      },
      'lease.hasEstimatedOperatingCosts': {
        short: "Estimated Operating Costs",
      },
      'lease.estimatedOperatingCosts': {
        short: "Amount"
      },
      'lease.definedTerms.originalName': {
        short: "Title of Original Lease",
      },
      'lease.definedTerms.premisesName': {
        short: "Premises Definition",
      },
      'lease.definedTerms.baseRent': {
        short: "Base Rent Definition",
      },
      'lease.definedTerms.additionalRent': {
        short: "Additional Rent Definition",
      },
      'lease.definedTerms.tiCam': {
        short: "TICAM Definition",
      },
    };

    function getMergedLabels(customLabels, full) {
      customLabels = _.chain(customLabels)
        .keyBy('key')
        .value();

      // merge default labels with custom labels
      var mergedLabels = _.chain()
        .concat(_.keys(defaultLabels), _.keys(customLabels))
        .uniq()
        .zipObject()
        .mapValues(function(val, key) {
          var customLabel = customLabels[key];
          var defaultLabel = defaultLabels[key];
          var label = {};

          if (customLabel && _.isString(customLabel.fullDesc)) {
            label.full = customLabel.fullDesc;
          } else {
            label.full = defaultLabel && defaultLabel.full;
          }

          if (customLabel && _.isString(customLabel.shortDesc)) {
            label.short = customLabel.shortDesc;
          } else {
            label.short = defaultLabel && defaultLabel.short;
          }

          return label;
        })
        .value();
      // merge full description with short description
      var labels = _.mapValues(mergedLabels, function(v) {
        if (full) {
          return _.isString(v.full) ? v.full : v.short;
        } else {
          return _.isString(v.short) ? v.short : v.full;
        }
      });
      return labels;
    }

    function normalize(key) {
      return key
        .replace(/\[[\d]+\]/g, '')
        .replace(/\[\$parent.\$parent.\$parent.\$index\]/g, '')
        .replace(/\[\$parent.\$parent.\$index\]/g, '')
        .replace(/\[\$parent.\$index\]/g, '')
        .replace(/\[\$index\]/g, '')
        .replace(/\[termIndex]/g, '');
    }

    return {
      normalize: normalize,
      getMergedLabels: getMergedLabels,
    };
  },
]);
